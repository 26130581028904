import { Component, OnInit } from '@angular/core';
import { ServService } from '../../../app/serv.service';
import { LOCALE_TEXT } from '../../../app/Additions/constants';
import { Router } from '@angular/router';
import * as moment from "moment"

@Component({
  selector: 'app-graduate-report',
  templateUrl: './graduate-report.component.html',
  styleUrls: ['./graduate-report.component.css']
})
export class GraduateReportComponent implements OnInit {

  backgroundColor: string;
  private column;
  private rowData;
  public gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private defaultColDef;
  private pivotMode;

  public static parentsAndChildrenFilterState=null;
  public static parentsAndChildrenSortState=null;
  public static parentsAndChildrenGroupState=[];
  public static parentsAndChildrenColState=null;

  constructor(public serv: ServService, private router: Router) { }
  ngOnInit() {
    this.serv.bfromParentsAndChild = false;
    this.serv.visible = true;
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      enableCharts: true,
      defaultColDef: {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true
    },
      onGridReady: this.onGridReady
    };
    
    this.pivotMode=true;
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
    this.defaultColDef = {
      enableValue: true,
    };
    this.column = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 80,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML = '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll('#edit')[0];
          eButton.addEventListener('click', function () {
            params.context.componentParent.editFamily(params.data);
          });
          return eDiv;
        }
      },
      {
        headerName: "שורה", field: "indexRow", cellRenderer: function (params) {
          let z = '<p>' + (params.node.rowIndex + 1) + '</p>';
          return z;
        },
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה",
        field: "nvLastName",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האב",
        field: "nvFirstNameF",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אב",
        field: "nvIdentityF",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה אב",
        field: "dBirthDateF",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceF",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת עבודה",
        field: "nvWorkAddressF",
        width: 135,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האם",
        field: "nvFirstNameM",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אם",
        field: "nvIdentityM",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה אם",
        field: "dBirthDateM",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceM",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת עבודה",
        field: "nvWorkAddressM",
        width: 135,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מיקוד",
        field: "nvZipCode",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מספר תלמידים",
        field: "iNumChildren",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "יתרה לתשלום",
        field: "nBalance",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הו''ק",
        field: "isDD",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: 'אשראי',
        field: "isCC",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: " פרטי",
        field: "nvFirstName",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה",
        field: "dBirthDate",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "מ.זהות",
        field: "nvIdentity",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה עברי",
        field: "nvJewBirthDate",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "גיל",
        field: "iAge",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "קופ'ח",
        field: "nvKupatCholimName",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.כניסה",
        field: "dBeginDate",
        type: "dateColumn",
        sortable: true
      },

      {
        headerName: 'שכ"ל',
        field: "nTuitionAmount",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הנחה",
        field: "nDiscountAmount",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "זיכוי",
        field: "nRefund",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף נוסף",
        field: "nMealsAmount",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה",
        field: "nTransAmount",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף הסעה",
        field: "nvTranspRateName",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'חיובים נוספים',
        field: "nAmount",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ס'ה",
        field: "nTotal",
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סניף",
        field: "nvBranchName",
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "סטטוס",
        field: "nvStatusName",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה הלוך",
        field: "nvTranspGoType",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'הסעה חזור ',
        field: "nvTranspReturnType",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה שישי",
        field: "nvTranspReturnFriType",
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'הערות',
        field: "nvComments",
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
    this.getReport(JSON.parse(localStorage.getItem("familyStatuses")));
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(GraduateReportComponent.parentsAndChildrenFilterState);
  };
  rowDataChanged(event){
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(GraduateReportComponent.parentsAndChildrenFilterState);
  }
  filterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();

    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }

  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
  }

  sortChanged() {
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: 'פרטי תלמידים',
    //   sheetName: 'xls'
    // };
    // this.gridOptions.api.exportDataAsCsv(params);
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: 'פרטי תלמידים'
    };
    this.gridApi.exportDataAsExcel(params);
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();

    if (this.serv != undefined) {
      if(GraduateReportComponent.parentsAndChildrenColState)
    this.gridOptions.columnApi.setColumnState(GraduateReportComponent.parentsAndChildrenColState);
    if(GraduateReportComponent.parentsAndChildrenGroupState[0])
    this.gridOptions.columnApi.setColumnGroupState(GraduateReportComponent.parentsAndChildrenGroupState);
    if(GraduateReportComponent.parentsAndChildrenSortState)
    this.gridOptions.api.setSortModel(GraduateReportComponent.parentsAndChildrenSortState);
    if(GraduateReportComponent.parentsAndChildrenFilterState)
    this.gridOptions.api.setFilterModel(GraduateReportComponent.parentsAndChildrenFilterState);
  }
    //  params.api.sizeColumnsToFit();
  }
  getReport(lStatus) {
    this.serv.GetGraduateAndOutgoingReport().then(res => {
      for (let i = 0; i < (res as any[]).length; i++) {
        res[i].dBeginDate = res[i].dBeginDate != null ? moment(res[i].dBeginDate).format("DD/MM/YYYY") : "";
        res[i].dBirthDate = res[i].dBirthDate != null ? moment(res[i].dBirthDate).format("DD/MM/YYYY") : "";
        res[i].dBirthDateF = res[i].dBirthDateF != null ? moment(res[i].dBirthDateF).format("DD/MM/YYYY") : "";
        res[i].dBirthDateM = res[i].dBirthDateM != null ? moment(res[i].dBirthDateM).format("DD/MM/YYYY") : "";
      }
      this.rowData = res;
      if(this.gridOptions.api)
       this.gridOptions.api.setFilterModel(GraduateReportComponent.parentsAndChildrenFilterState);
    });
  }
  editFamily(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId;
    this.serv.currentFamily = Family;

    GraduateReportComponent.parentsAndChildrenColState = this.gridOptions.columnApi.getColumnState();
    GraduateReportComponent.parentsAndChildrenGroupState = this.gridOptions.columnApi.getColumnGroupState();
    GraduateReportComponent.parentsAndChildrenSortState = this.gridOptions.api.getSortModel();
    GraduateReportComponent.parentsAndChildrenFilterState = this.gridOptions.api.getFilterModel();

    this.router.navigateByUrl('/header/families/tabs/parentDetails');
    this.serv.bfromGraduate = true;
    this.serv.visible = false;
  }
}
