export class Default {
    iDebitDayFamilyId: number
    iDirectDebitAssociationId: number
    iCreditAssociationId: number
    iYearId: number
    dCreditFamilyFromDate: Date
    dDebitFamilyFromDate: Date
    bRegisteredStatus: boolean
    bStudyingStatus: boolean
    bGraduateStatus: boolean
    bDebtStatus: boolean
    bQuitStatus: boolean
    lFamilyStatus: Array<number>
    iCCCompanyType:number
    iFixedAmountType:number
}
