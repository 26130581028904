export class DataToImport {
    iDataToImportID: number
	iFamilyIdToAttach: number
    iImportIdToAttach: number
	bUpdateDetails: boolean
	iRowNumber: number
    nvFamilyToAttach:string=null
    nvFamilyName:string=null
    nvPhone:string=null
    nvCityName:string=null
    nvAddress:string=null
    nvNumHouse:string=null
    nvNeighborhood:string=null
    nvFirstNameF:string=null
    nvIdentityF:string=null
    nvMobileF:string=null
    nvFirstNameM:string=null
    nvIdentityM:string=null
    nvMobileM:string=null
    nvFirstNameC:string=null
    nvIdentityC:string=null
}