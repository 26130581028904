import { Component, OnInit, Inject } from '@angular/core';
import "ag-grid-enterprise";
import { GridApi } from "ag-grid/dist/lib/gridApi";
import { LOCALE_TEXT } from '../../app/Additions/constants';
import { ServService } from '../../app/serv.service';

import * as moment from "moment"
import { CodeTable } from '../../Models/CodeTable';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MobilityToAssociationsComponent } from '../mobility-to-associations/mobility-to-associations';
import { MatCheckboxModule } from '@angular/material/checkbox';


@Component({
  selector: 'app-checks-management',
  templateUrl: './checks-management.html',
  styleUrls: ['./checks-management.css']
})
export class ChecksManagementComponent implements OnInit {
  backgroundColor: string;
  visible: boolean = false;
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;
  public genderCellRendererף
  public numericCellEditor
  public moodCellRenderer
  public moodEditor
  public countryCellRenderer
  lCheck: Array<number>
  constructor(public service: ServService, public dialogRef: MatDialogRef<ChecksManagementComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.backgroundColor = this.service.backgroundColor[2];
    this.lCheck = new Array<number>();
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive:true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {

    this.gridOptions = {
      onGridReady: this.onGridReady,
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      // DomLayout : "forPrint"
    },

      this.columnDefs = [
        {
          headerName: "בחירה",
          field: "iCreditId",
          width: 120,
          suppressFilter: true,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<input type="checkbox" id="edit" >';
            var eButton = eDiv.querySelectorAll('#edit')[0];
            eButton.addEventListener('click', function () {
              params.context.componentParent.addCheck(params);
            });

            return eDiv;
          }
        },
        {
          headerName: "משפחה",
          field: "nvFamilyName",
          width: 150,
          filter: "agTextColumnFilter"
        },


        {
          headerName: "מס שיק",
          field: "iCheckNum",
          width: 150,
          filter: "agTextColumnFilter"
        },


      ]
    //  if(this.data[1]==1)
    //     this.GetCheckManagement()
    //  else this.GetCheckList()
  };

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

  }
  GetCheckList() {
    this.columnDefs.push(
      {
        headerName: "סכום",
        field: "nAmount",
        editable: true,
        width: 150,
      },
      {
        headerName: "יתרה",
        field: "nBalance",
        width: 150,
        filter: "agTextColumnFilter"
      });
    this.service.GetCheckList(this.data[0]).then(res => {
      if (res) {
        this.rowData = res;

      }

    }).catch(err => { console.log("err-----" , err); return false; })
  }
  // GetCheckManagement(){
  //   this.columnDefs.push(
  //     {
  //       headerName: "סכום",
  //       field: "nAmount",

  //        width: 150,
  //       filter: "agTextColumnFilter"
  //     },
  //     {
  //     headerName: "סטטוס",
  //     field: "nvStatusType",
  //      width: 150,
  //     filter: "agDateColumnFilter"
  //   });
  //   this.service.GetCheckManagement(this.data[0]).then(res => {
  //       if (res) {
  //         this.rowData = res;

  //       }

  //     }).catch(err => { console.log("err-----" + err); return false; })
  // }

  closeDialog() {
    this.dialogRef.close()
  }
  cellEditingStopped(params) {
    this.rowData.forEach(element => {
      if (element.iCreditId == params.data.iCreditId) {
        element = params.data
        this.gridOptions.gridApi.setRowData(this.rowData);
      }
    });

  }
  addCheck(params) {
    params.nAmount = params.nBalance;
    this.rowData.forEach(element => {
      if (element.iCreditId == params.iCreditId) {
        element = params
        //  this.gridOptions.gridApi.setRowData(this.rowData);
      }
    });

    this.lCheck.push(params.iCreditId);
  }


}
