import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ServService } from "../../../app/serv.service";
import * as moment from "moment";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { AppProxy } from "src/app/app.proxy";
import { ChartOptions } from 'src/Models/ChartOptions';
@Component({
  selector: "app-payments-details",
  templateUrl: "./payments-details.component.html",
  styleUrls: ["./payments-details.component.css"],
})
export class PaymentsDetailsComponent implements OnInit {
  chartOptions!: Partial<any>;
  constructor() {
     this.chartOptions = new ChartOptions();
  }

  ngOnInit() {



  }
}
