import { FileObj } from "./FileObj";

export class ParentDetails {
    nvFamilyName: string
    nvFirstNameF: string
    nvIdentityF: string
    nvPassportF: string
    iIdentityTypeF: number = 3
    nvPhoneF: string
    nvWorkPlaceF: string
    nvWorkAddressF: string
    nvFirstNameM: string
    nvIdentityM: string
    nvPassportM: string
    iIdentityTypeM: number = 3
    nvPhoneM: string
    nvWorkPlaceM: string
    nvWorkAddressM: string
    nvAddress: string
    iAddressType: number
    nvCityName: string
    nvZipCode: string
    nvNeighborhood: string
    nvNumHouse: string
    iCityType: number
    nvPhone: string
    nvLastNameF: string = undefined
    nvLastNameM: string = undefined
    nvAccountName:string=undefined
    nvAccountIdentity:string=undefined
    identityF:FileObj
    identityM:FileObj
    nvComments:string
    dBirthDateM:Date
    dBirthDateF:Date
    nBalance:number

    iChildrenCount: number
    nvCommunity: string
    nvEmail: string
}
