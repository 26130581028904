import { Component, OnInit } from "@angular/core";
import * as ApexCharts from "apexcharts";
import { Router } from "@angular/router";
import { ServService } from "../../../app/serv.service";
import * as moment from "moment";
import { AppProxy } from "src/app/app.proxy";
import { ChartOptions } from "src/Models/ChartOptions";
import { CreditPercentChart } from "src/Models/CreditPercentChart";

@Component({
  selector: "app-credit-percent-chart",
  templateUrl: "./credit-percent-chart.component.html",
  styleUrls: ["./credit-percent-chart.component.css"],
})
export class CreditPercentChartComponent implements OnInit {
  chartOptions!: Partial<any>;
  currentiFamilyIdTemp;
  iBranchId: number;
  lStatus: Array<number>;

  //serverData: CreditPercentChart[]=[];
  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp;
  }

  constructor(
    public serv: ServService  ) {
    this.chartOptions = new ChartOptions();

  }

  ngOnInit() {
    this.serv.visible = true;
    this.lStatus = JSON.parse(localStorage.getItem("familyStatuses"));
    this.refresh(this.lStatus);
  }

  public moneyFormatter(val) {
    let str = parseInt(val.toString()).toString();
    let numList: string[] = str.split("");
    if (numList.length > 3) {
      for (let i = numList.length - 3; i > 0; i -= 3) {
        numList.splice(i, 0, ",");
      }
    }
    return numList.join("") + "\u20AA";
  }

  getMonthValues(dateString: string): number {
    const match = dateString.match(/\/Date\((\d+)\+(\d{4})\)\//);
    if (match) {
      const timestamp = parseInt(match[1]);
      const offsetHours = parseInt(match[2].slice(0, 2));
      const date = new Date(timestamp + offsetHours * 60 * 60 * 1000);
      const month = date.getMonth() + 1;
      //  const monthName = date.toLocaleString("en-US", { month: "long" });
      return month;
    } else {
      return 0;
    }
  }

  refresh(lStatus) {
    // Initialize the chart
    var chart = new ApexCharts(
      document.querySelector("#creditPercent"),
      this.chartOptions
    );
    chart.render();
  
    // Fetch data from the server and update the chart
    this.serv.GetCreditPercentChart(lStatus).then((res) => {
      const serverData = res;
  
      // Format month_date to MM/YYYY
      const xAxisCategories = serverData.map((item) => {
        const date = new Date(item.month_date);
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
          month: '2-digit',
          year: 'numeric'
        }).format(date);
        return formattedDate;
      });
  
      const seriesData = [
        {
          name: "תשלומים",
          data: serverData.map((item) => item.nPaied),
        },
        {
          name: "לתשלום",
          data: serverData.map((item) => item.nSumToBePaied),
        },
        {
          name: "חובות",
          data: serverData.map((item) => item.nAmount),
        },
        {
          name: "צפי שוטף",
          data: serverData.map((item) => item.nExpectedPayments),
        },
        {
          name: "צפי חובות /שונות",
          data: serverData.map((item) => item.nDebts),
        },
      ];
  
      // Update chart options with new x-axis categories
      chart.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
  
      // Update the series data
      chart.updateSeries(seriesData);
    }).catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
  
  

      /*  this.chartOptions.xaxis = {
    ...this.chartOptions.xaxis,
    categories: serverData.map((item) => this.getMonthValues(item.month_date)),
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "dd MMM",
        hour: "HH:mm",
      },
    },
  }; */
  //  });
  //}
}