export class ChartOptions {
  series: any = [];
  xaxis: any = { categories: [] };
  yaxis: any = { show: false };
  chart: any = {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
    },
    bar: { columnWidth: "30%" },
    zoom: {
      enabled: true,
    },
  };
  responsive: any = [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ];
  dataLabels: any = {
    enabled: true,
    formatter: function (val) {
      let str = parseInt(val.toString()).toString();
      let numList: string[] = str.split("");
      if (numList.length > 3) {
        for (let i = numList.length - 3; i > 0; i -= 3) {
          numList.splice(i, 0, ",");
        }
      }
      return numList.join("") + "\u20AA";
    },
    style: {
      fontSize: "0.8rem",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      colors: ["#004085", "#856404", "#721c24", "#155724", "#0c5460"], //bootstrap4 colors
    },
  };
  tooltip: any = {
    enabled: true,
    shared: true,
    inverseOrder: true,
    hideEmptySeries: true,
    intersect: false,
    y: {
      formatter: function (val) {
        let str = parseInt(val.toString()).toString();
        let numList: string[] = str.split("");
        if (numList.length > 3) {
          for (let i = numList.length - 3; i > 0; i -= 3) {
            numList.splice(i, 0, ",");
          }
        }
        return numList.join("") + "\u20AA";
      },
    },
  };
  colors: any = ["#cce5ff", "#fff3cd", "#f8d7da", "#d4edda", "#d1ecf1"]; //bootstrap4 bg-colors
  plotOptions: any = {
    bar: {
      columnWidth: "75%",
      endingShape: "flat",
      dataLabels: {},
    },
  };
  stroke: any = {
    show: true,
    colors: ["#b8daff", "#ffeeba", "#f5c6cb", "#c3e6cb", "#bee5eb"], //bootstrap4 border-colors
    curve: "straight",
    lineCap: "butt",
    width: 2,
    dashArray: 0,
  };
  fill: any = { opacity: 0.6 };
  legend: any = { position: "bottom", horizontalAlign: "center" };
  noData: {
    text: "מקבל נתונים ...";
    align: "center";
    verticalAlign: "middle";
    offsetX: 0;
    offsetY: 0;
    style: {
      color: undefined;
      fontSize: "14px";
      fontFamily: undefined;
    };
  };
}
