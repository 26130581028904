export class Association
{
    iAssociationId:number =-1
    nvAssociationName:string=undefined
    nvAssociationCode:string=undefined
    nvAssociationSend:string=undefined
    nvTerminalNum:string=undefined
    nvTerminalPass:string=undefined
    nvTerminalUser:string=undefined
    iActive:number=undefined
    nvActiv:string=undefined
    nvRivchitToken:string=null
    iReceiptType:number
    nReturnFee: number=undefined
}