export class ReportsDet{
    nvFieldName:string
    nvFieldTitle :string
    // iFieldSequnce :number=undefined
    // nvFieldFilter:string
    // nvFieldFilterOperator:string
    // iFieldOrderby:number=undefined
    // nvFieldOrderbyType:string
    nFieldWidth:number
 }

