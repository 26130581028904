export class TransportRoute{
    iTransportRouteId:number=-1
   iBranchId:number=-1
   iTransportRouteType:number=-1
   nvBranchName:string=undefined
   nvTransportRouteName:string
   nvTransportRouteType:string
   nvEscortName :string
   nvEscortPhone :string
   nvComment:string
  
}


