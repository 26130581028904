import { Directive, ElementRef, HostListener, Input } from'@angular/core';
@Directive({
    selector: '[onReturn]'
})
export class OnReturnDirective {
    private el: ElementRef;
    @Input() onReturn: string;
    constructor(private _el: ElementRef) {
        this.el = this._el;
    }
    @HostListener('keydown', ['$event']) onKeyDown(e) {
        if ((e.which == 13 || e.keyCode == 13)) {
            e.preventDefault();
            for (let i = 0; i <e.srcElement.form.length; i++) {
                if(e.srcElement.form[i].id==e.srcElement.id){
                    e.srcElement.form[i+1].focus() // 
                    return;
                }
                  
              }
            return;
        }
       
    }

}