import { FileObj } from "./FileObj";

export class UpdateChildDetails {
    iFamilyId: number = -1
    iChildId: number = -1
    iChildYearId: number = null
    iClassId: number = 0
    nvFirstName: String = undefined
    nvLastName: String = undefined
    dBirthDate: Date = null
    nvJewBirthDate: String = undefined
    nvIdentity: string = undefined
    iIdentityType: number = 0
    nvPassport: string = undefined
    dRegisteredDate: Date = null
    dBeginDate: Date = undefined
    dEndDate: Date = null
    nvLevel: number = undefined
    iStatusType: number = undefined
    iTuitionRateId: number = null
    nTuitionAmount: number = null
    nDiscountPerc: number = null
    nDiscountAmount: number = null
    bDiscSet: Boolean = undefined
    iTranspRateId: number = null
    nTransAmount: number = null
    iMealsRateId: number = null
    nMealsAmount: number = null
    iTranspGoType: number = null
    iTranspReturnType: number = null
    iTranspReturnFriType: number = null
    iTranspSpecialType: number = null
    nvStopNumGo: string = null
    nvStopNumRetern: string = null
    nvStopNumFri: string = null
    nvStopNumSpecial: string = null
    nvComment: String = undefined
    nvComment1: string = undefined
    nvLeval: string = undefined
    iBranchId: number = null
    dTuitionRateUpdatedToDate: Date = null
    dTuitionRateUpdatedFromDate: Date = null
    dTranspRateUpdatedFromDate: Date = null
    dTranspRateUpdatedToDate: Date = null
    dMealsRateFromDate: Date = null
    dMealsRateToDate: Date = null
    iKupatCholim: number
    bHealthDeclaration: boolean = false
    identity: FileObj
    nRefund: number
    nvRefundDescription: string
    nvInstitutionSymbol: string
    // lDebitAddition:Array<DebitAddition>=[]
    nvNickname: string
    // iChildrenCount: number
    iFamilyLocation: number
    // nvCommunity: string
}
export class DebitAddition {
    iDebitId: number = undefined
    dDebitMonth: any = null
    nAmount: number = undefined
    iPaymentNum: number = 1;
    nvComment: string = undefined
    bDel: boolean = false;
    iStatusType: number
    iAssociationId: number
    dDebitDate: any
    dtLastModifyDate: any = new Date()
    iRateType: number = null
    iRateId:number = null
}



