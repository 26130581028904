export class FamilyContacts {
    iGrandParentId: number
    iRelationshipType: number
    nvRelationshipType: string
    nvFirstName: string
    nvLastName: string
    nvPhone: string
    nvMobile: string
    iCityType: number
    nvCityName: string
    nvAddress: string
    nvNumHouse: string
    nvNeighborhood: string
    nvZipCode: string
}