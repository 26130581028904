import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID  } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { FamiliesComponent } from '../Components/families/families.component';
import { HeaderComponent } from '../Components/header/header.component';
import { LoginComponent } from '../Components/login/login.component';
import { DebitComponent } from '../Components/debit/debit.component';
import { AgGridModule } from "ag-grid-angular";
//import { NguiDatetimePickerModule } from '@ngui/datetime-picker'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppProxy } from './app.proxy';
import { ServService, WindowRef } from './serv.service';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { dailog } from './Additions/dailog/dailog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatInputModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';     //accordion and accordion tab
// import {MenuItem} from 'primeng/api';


import { parentDetailsComponent } from '../Components/parentDetails/parentDetails.component';


import { StepsModule, MenuItem } from 'primeng/primeng';
import { tabs } from '../Components/tabs/tabs';
import { ChildrenDetailsComponent } from '../Components/children-details/children-details.component';

import { LastDebitsComponent } from '../Components/last-debits/last-debits.component';
import { DetailsPaymentComponent } from '../Components/details-payment/details-payment.component';
import { UpdateChildrenComponent } from '../Components/update-children/update-children.component';


import { MomentModule } from 'angular2-moment';
import 'moment';
import { FormChildrenDetailsComponent } from './Additions/form-children-details/form-children-details.component';
import { DebitDetailsToMonthComponent } from '../Components/debit-details-to-month/debit-details-to-month.component';


import { newpopup } from './Additions/popup';
import { ChildrenListComponent } from '../Components/Reports/children-list/children-list.component';
import { ParentsListComponent } from '../Components/Reports/parents-list/parents-list.component';
import { ParentsWithBalanceComponent } from '../Components/Reports/parents-with-balance/parents-with-balance.component';
import { ReportsComponent } from '../Components/Reports/reports/reports.component';
import { CrebitComponent } from '../Components/credit/credit.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FormPaymentDetailsComponent } from './Additions/form-payment-details/form-payment-details.component';
import { SettingComponent } from '../Components/settings/settings.component';
import { DialogModule } from 'primeng/dialog';


// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { MatRadioModule } from '@angular/material/radio';
import { OnReturnDirective } from './Additions/keydownDirectiv';
import { CanActivateComponent } from './CanLoad.service';
import { DebitDetailsComponent } from '../Components/debit-details/debit-details.component';
import { FamilyCreditComponent } from '../Components/family-credit/family-credit.component';
import { FamilyContactsComponent } from '../Components/family-contacts/family-contacts.component';
import { SafePipe } from './Additions/Pipe';
import { MobilityToAssociationsComponent } from '../Components/mobility-to-associations/mobility-to-associations';
import { ChecksManagementComponent } from '../Components/checksManagement/checks-management.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { UpdateClassChildrenComponent } from '../Components/Reports/UpdateClassChildren/update-class-children';
import { SavedReportsComponent } from '../Components/Reports/saved-reports/saved-reports.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ParentsAndChildrenComponent } from '../Components/Reports/parents-and-children/ParentsAndChildrenComponent';
// {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { NgxSelectModule } from 'ngx-select-ex';
import { CreditReportComponent } from '../Components/Reports/credit-report/credit-report.component';
import { DebitReportComponent } from '../Components/Reports/debit-report/debit-report.component';
// import { DatepickerModule } from 'angular-mat-datepicker'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxComponent } from 'src/app/Additions/mat-checkbox/mat-checkbox/mat-checkbox.component';
import { MatCheckboxService } from 'src/app/Additions/mat-checkbox/mat-checkbox/mat-checkbox.service';
import { paymentMethodReportsComponent } from '../Components/Reports/paymentMethod/paymentMethod-component';
import { WindowRef2, HebrewDateService } from '../hebrew-date.service';
import { FamilyContactsReportComponent } from '../Components/Reports/family-contacts-report/family-contacts-report.component';
import { GraduateReportComponent } from '../Components/Reports/graduate-report/graduate-report.component';
import { PreventDoubleClickDirective } from './throttle-click.directive';
import { FamilyTasksComponent } from '../Components/family-tasks/family-tasks.component';
import { FamilyTasksReportComponent } from '../Components/Reports/family-tasks-report/family-tasks-report.component';
import { ChartsComponent } from "../Components/Charts/chart/charts.component";
import { CreditPercentChartComponent } from "../Components/Charts/credit-percent-chart/credit-percent-chart.component";
import { PaymentsDetailsComponent } from "../Components/Charts/payments-details/payments-details.component";

import { MultiSelectModule } from "primeng/multiselect";

import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
registerLocaleData(localeHe);
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

export const HEBREW_DATE_FORMATS: MatDateFormats = {
  parse: { dateInput: 'DD/MM/YYYY' },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    FamiliesComponent,
    HeaderComponent,
    LoginComponent,
    DebitComponent,
    dailog,
    tabs,
    parentDetailsComponent,
    ChildrenDetailsComponent,
    UpdateChildrenComponent,
    LastDebitsComponent,
    DetailsPaymentComponent,
    FormChildrenDetailsComponent,
    DebitDetailsToMonthComponent,
    SettingComponent,
    newpopup,
    ChildrenListComponent,
    ReportsComponent,
    ParentsListComponent,
    ParentsWithBalanceComponent,
    CrebitComponent,
    FormPaymentDetailsComponent,
    OnReturnDirective,
    DebitDetailsComponent,
    FamilyCreditComponent,
    FamilyContactsComponent,
    SafePipe,
    MobilityToAssociationsComponent,
    ParentsAndChildrenComponent,
    ChecksManagementComponent,
    UpdateClassChildrenComponent,
    SavedReportsComponent,
    paymentMethodReportsComponent,
    CreditReportComponent,
    DebitReportComponent,
    MatCheckboxComponent,
    FamilyContactsReportComponent,
    GraduateReportComponent,
    PreventDoubleClickDirective,
    FamilyTasksComponent,
    FamilyTasksReportComponent,
    ChartsComponent,
    CreditPercentChartComponent,
    PaymentsDetailsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    DialogModule,
    CKEditorModule,
    MomentModule,
    MatNativeDateModule,
    NgxSelectModule,
    NgIdleKeepaliveModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RouterModule.forRoot(
      [
        { path: "", component: LoginComponent },
        {
          path: "header",
          component: HeaderComponent,
          children: [
            {
              path: "families",
              component: FamiliesComponent,
              canActivate: [CanActivateComponent],
              children: [
                {
                  path: "tabs",
                  component: tabs,
                  children: [
                    {
                      path: "parentDetails",
                      component: parentDetailsComponent,
                    },
                    {
                      path: "children-details",
                      component: ChildrenDetailsComponent,
                      children: [
                        {
                          path: "update-children-details",
                          component: UpdateChildrenComponent,
                        },
                      ],
                    },
                    {
                      path: "last-debits",
                      component: LastDebitsComponent,
                      children: [
                        {
                          path: "details-to-month",
                          component: DebitDetailsToMonthComponent,
                        },
                      ],
                    },
                    { path: "family-credit", component: FamilyCreditComponent },
                    {
                      path: "details-payment",
                      component: DetailsPaymentComponent,
                    },
                    { path: "family-tasks", component: FamilyTasksComponent },
                    {
                      path: "family-contacts",
                      component: FamilyContactsComponent,
                    },
                  ],
                },
              ],
            },
            {
              path: "debit",
              component: DebitComponent,
              canActivate: [CanActivateComponent],
              children: [
                {
                  path: "debit-details",
                  component: DebitDetailsComponent,
                },
              ],
            },
            {
              path: "credit",
              component: CrebitComponent,
              canActivate: [CanActivateComponent],
              children: [
                {
                  path: "mobility-to-associations",
                  component: MobilityToAssociationsComponent,
                },
                {
                  path: "checks-management",
                  component: ChecksManagementComponent,
                },
              ],
            },
            {
              path: "reports",
              component: ReportsComponent,
              canActivate: [CanActivateComponent],
              children: [
                {
                  path: "children-list",
                  component: ChildrenListComponent,
                },
                {
                  path: "parents-list",
                  component: ParentsListComponent,
                },
                {
                  path: "parents-with-balance",
                  component: ParentsWithBalanceComponent,
                },
                {
                  path: "parents-and-children",
                  component: ParentsAndChildrenComponent,
                },
                {
                  path: "update-class-children",
                  component: UpdateClassChildrenComponent,
                },
                {
                  path: "saved-reports",
                  component: SavedReportsComponent,
                },
                {
                  path: "paymentMethod-reports",
                  component: paymentMethodReportsComponent,
                },
                {
                  path: "credit-report",
                  component: CreditReportComponent,
                },
                {
                  path: "debit-report",
                  component: DebitReportComponent,
                },
                {
                  path: "family-contacts-report",
                  component: FamilyContactsReportComponent,
                },
                {
                  path: "family-tasks-report",
                  component: FamilyTasksReportComponent,
                },
                {
                  path: "graduate-report",
                  component: GraduateReportComponent,
                },
              ],
            },
            {
              path: "settings",
              component: SettingComponent,
              canActivate: [CanActivateComponent],
            },
            {
              path: "charts",
              component: ChartsComponent,
              canActivate: [CanActivateComponent],
              children: [
                {
                  path: "credit-percent",
                  component: CreditPercentChartComponent,
                },
                {
                  path: "payments-details",
                  component: PaymentsDetailsComponent,
                },
              ],
            },
          ],
        },
        { path: "login", component: LoginComponent },
      ],
      { useHash: true }
    ),
    AgGridModule.withComponents([]),
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    AccordionModule,
    StepsModule,

    MultiSelectModule,
  ],
  entryComponents: [dailog, MatCheckboxComponent],
  providers: [
    AppProxy,
    WindowRef,
    WindowRef2,
    ServService,
    HebrewDateService,
    CanActivateComponent,
   // { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: LOCALE_ID, useValue: 'he' },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },
    { provide: MAT_DATE_FORMATS, useValue: HEBREW_DATE_FORMATS },
    MatCheckboxService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [OnReturnDirective],
})
export class AppModule {}
