import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  ViewChild
} from "@angular/core";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { ServService } from "../../../app/serv.service";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-paymentMethodReports",
  templateUrl: "./paymentMethod-component.html",
  styleUrls: ["./paymentMethod-component.scss"]
})
export class paymentMethodReportsComponent implements OnInit {
  gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnApi: any;
  private pivotMode;
  columnTypes;
  columnChildren;
  private rowData:any[];
  backgroundColor:string;
  constructor(private serv:ServService,private router:Router

  ) {

  }

  ngOnInit() {
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      enableCharts: true,
      defaultColDef: {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true
    },
      // domLayout: "forPrint",
      onGridReady: this.onGridReady
    }

//fill the grid with data
    this.serv
    .GetPaymentDetailsReport()
    .then(res => {
       this.rowData = res;
       this.rowData.forEach(row=>{
         if(row.dPausedToDate)
         row.dPausedToDate=moment(row.dPausedToDate).format("DD/MM/YYYY");
         if(row.dEndDate)
         row.dEndDate=moment(row.dEndDate).format("DD/MM/YYYY");
       })
    });

    this.pivotMode=true;
    this.columnChildren = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 120,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.editFamily(params.data);
          });

          return eDiv;
        }
      },
      {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "שם משפחה ושם האב",
        field: "nvFamilyName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תלמידים לומדים",
        field: "iActiveChildrenCount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "יום חיוב",
        field: "nvDebitDay",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "יום חיוב אמצעי תשלום",
        field: "nvDebitDayPaymentMethod",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קדימות",
        field: "ipriorityType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג אמצעי תשלום",
        field: "nvPaymentMethodType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatusType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מושהה עד לתאריך",
        field: "dPausedToDate",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ספרות אחרונות של הכרטיס4 ",
        field: "nvCardLastFourDigits",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תוקף",
        field: "nvExpirationDate",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "3 ספרות",
        field: "iCVV",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם כרטיס",
        field: "nvCardName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם בנק",
        field: "nvBankName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס סניף",
        field: "iBranchNumber",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס חשבון",
        field: "iaccountNumber",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם בעל חשבון",
        field: "nvAccountName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מז בעל חשבון",
        field: "nvAccountIdentity",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום גביה קבוע",
        field: "nFixedAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום גביה מרבי",
        field: "nMaxAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך סיום",
        field: "dEndDate",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עבור סוג תעריף",
        field: "nvRateType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "גביה חיצונית",
        field: "bExternalCharge",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      }
      

    ];
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };

    }
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.closeToolPanel();
    }

    clearFiltering() {
      this.gridOptions.api.setRowData(this.rowData);
      let nodes = this.gridApi.getRenderedNodes();
      nodes.forEach(n => n.setSelected(false));
    }

    editFamily(Family) {
      this.serv.currentiFamilyId = Family.iFamilyId;
      this.serv.currentFamily = Family;
      this.router.navigateByUrl("/header/families/tabs/parentDetails");
      this.serv.bfromParentsList = true;
      this.serv.visible = false;
    }
    onBtExport() {
      // var params = {
      //   skipHeader: false,
      //   allColumns: false,
      //   fileName: "דוח אמצעי תשלום",
      //   sheetName: "xls"
      // };
      // this.gridOptions.api.exportDataAsCsv(params);

      var params = {
        skipHeader: false,
        allColumns: false,
        fileName: "דוח אמצעי תשלום"
      };
      this.gridApi.exportDataAsExcel(params);
    }
}
