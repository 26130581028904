import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from "@angular/core";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { ServService } from "../../../app/serv.service";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: 'app-family-contacts-report',
  templateUrl: './family-contacts-report.component.html',
  styleUrls: ['./family-contacts-report.component.css']
})
export class FamilyContactsReportComponent implements OnInit {

  gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnApi: any;
  private pivotMode;
  columnTypes;
  columnChildren;
  private rowData: any[];
  backgroundColor:string;

  // public static FamilyContactsFilterState=null;
  // public static FamilyContactsSortState=null;
  // public static FamilyContactstGroupState=[];
  // public static FamilyContactsColState=null;

  constructor(private serv: ServService, private router: Router

  ) {
  }

  ngOnInit() {
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      enableCharts: true,
      // domLayout: "forPrint",
      onGridReady: this.onGridReady,
      defaultColDef: {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true
    },
    }

    //fill the grid with data
    this.serv
      .GetFamilyContactsReport()
      .then(res => {
        this.rowData = res;
      });
    this.pivotMode=true;
    this.columnChildren = [
      // {
      //   headerName: "פתיחה",
      //   field: "iFamilyId",
      //   width: 120,
      //   suppressFilter: true,
      //   cellRenderer: function (params) {
      //     var eDiv = document.createElement("div");
      //     eDiv.innerHTML =
      //       '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
      //     var eButton = eDiv.querySelectorAll("#edit")[0];
      //     eButton.addEventListener("click", function () {
      //       params.context.componentParent.editFamily(params.data);
      //     });

      //     return eDiv;
      //   }
      // },
      {
        headerName: "איש קשר למשפחה",
        field: "nvFamilyName",
        width: 200,
        filter: "agTextColumnFilter",
        sortable: true
      }, {
        headerName: "קרבה",
        field: "nvRelationshipType",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "משפחה ",
        field: "nvLastName",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם פרטי",
        field: "nvFirstName",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון ",
        field: "nvPhone",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד",
        field: "nvMobile",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "רחוב",
        field: "nvAddress",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מיקוד",
        field: "nvZipCode",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
  }

  onBtExport() {
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "דוח אנשי קשר"
    };
    this.gridApi.exportDataAsExcel(params);
  }
  
  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
  }
  // editFamily(Family) {
  //   this.serv.currentiFamilyId = Family.iFamilyId;
  //   this.serv.currentFamily = Family;
    
  //   FamilyContactsReportComponent.FamilyContactsColState = this.gridOptions.columnApi.getColumnState();
  //   FamilyContactsReportComponent.FamilyContactstGroupState = this.gridOptions.columnApi.getColumnGroupState();
  //   FamilyContactsReportComponent.FamilyContactsSortState = this.gridOptions.api.getSortModel();
  //   FamilyContactsReportComponent.FamilyContactsFilterState = this.gridOptions.api.getFilterModel();

  //   this.router.navigateByUrl("/header/families/tabs/parentDetails");
  //   this.serv.bfromParentsList = true;
  //   this.serv.visible = false;
  // }
}
