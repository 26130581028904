import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsraelIdValidationService {

  constructor() { }

  validateIsraeliId(id: string): boolean {
    // Check if the ID is a string of 9 digits
    if (!/^\d{9}$/.test(id)) {
      return false;
    }

    // Split the ID into an array of digits
    const idDigits = id.split('').map(Number);

    // Calculate the verification digit
    const idSum = idDigits.slice(0, 8).reduce((sum, digit, index) => {
      const multiplier = (index % 2 === 0) ? 1 : 2;
      let value = digit * multiplier;
      if (value > 9) {
        value -= 9;
      }
      return sum + value;
    }, 0);

    const verificationDigit = (10 - (idSum % 10)) % 10;

    // Compare the calculated verification digit with the last digit of the ID
    return verificationDigit === idDigits[8];
  }
}
