import { Component, OnInit } from "@angular/core";
import { LOCALE_TEXT } from "../../app/Additions/constants";
import { ServService } from "../../app/serv.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DebitDetailsToMonthComponent } from "../debit-details-to-month/debit-details-to-month.component";
/* import { User } from "src/Models/User"; */

@Component({
  selector: "app-last-debits",
  templateUrl: "./last-debits.component.html",
  styleUrls: ["./last-debits.component.css"]
})
export class LastDebitsComponent implements OnInit {
  backgroundColor: string;
  public columnDefs;
  public column;
  public rowData;
  public rowData1;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;
    month;
  date = new Date();
  dFromDate: Date = undefined;
  isVisible = false;

  constructor(
    public service: ServService,
    private route: Router,
    private dMonthDailog: MatDialog
  ) {
    this.backgroundColor = this.service.backgroundColor[0];
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive:true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {
    this.dFromDate = this.service.default1.dDebitFamilyFromDate;
    this.service.visible = false;
    // this.getLastDebit();
    (this.gridOptions = {
      localeText: { LOCALE_TEXT },
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      onGridReady: this.onGridReady,
      enableRtl: true
    }),
      (this.columnDefs = [
        {
          headerName: "פתיחה",
          field: "iUserId",
          width: 100,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.monthDetails(params.data);
            });

            return eDiv;
          }
        },
        {
          headerName: "חודש",
          field: "nvMonth",
          width: 140,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "תאריך חיוב",
          field: "dDebitDate",
          width: 140,
          filter: "agDateColumnFilter",
          type: "dateColumn"
        },
        {
          headerName: "סכום לתשלום",
          field: "nAmountToPay",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום ששולם",
          field: "nAmountPaid",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "יתרה",
          field: "nBalance",
          width: 140,
          filter: "agNumberColumnFilter"
        }/* ,
        {
          headerName: "מצטבר",
          field: "nCumulativeBalance",
          width: 140,
          filter: "agNumberColumnFilter"
        } */
      ]);
      if (this.service.user.bCalcBalance )
      this.columnDefs.push({
        headerName: "מצטבר",
        field: "nCumulativeBalance",
        width: 140,
        filter: "agNumberColumnFilter"
      })
    this.getLastDebit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  getLastDebit() {
    let temp: number = 0;
    this.service
      .GetLastDebit(
      this.dFromDate,
      this.service.user.iUserId,
      this.service.currentiFamilyId
      )
      .then(res => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i].dDebitDate != null) {
              let x = res[i].dDebitDate;
              res[i].dDebitDate = moment(x).format("DD/MM/YYYY");
              res[i].dDebitDate2 = moment(x).format("MM/DD/YYYY");
              // console.log("res "+ res[i].dDebitDate)
            } else if (res[i].dDebitDate == null) {
              res[i].dDebitDate = "";
            }
          }
          this.rowData = res;
          // console.lo(res);
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  onChangeDate() {
    this.getLastDebit();
  }

  monthDetails(params) {
    // this.route.navigate(['/header/families/tabs/last-debits/details-to-month']);
    let detailsArr = [];

    detailsArr.push(params.dDebitDate2, params.nvMonth);
    this.dMonthDailog.open(DebitDetailsToMonthComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px",
      data: detailsArr
    });
  }
  downLoad() {
    this.onBtExport();
  }

  onBtExport() {
    console.log("xml", this.service);

    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "חיובים אחרונים"
/*       customHeader:
        "חיובים אחרונים למשפחת " +
        this.service.currentFamily.nvFamilyName +
        " " +
        this.service.currentFamily.nvFatherName +
        " ו" +
        this.service.currentFamily.nvMotherName +
        "\n" */
    };
    this.gridApi.exportDataAsExcel(params);
  }

  debitbyassociation() {
    this.service.GetDebitByAssociationReport(this.service.currentiFamilyId).then(res => {
      this.isVisible = true;

      this.column = [
        {
          headerName: "שם עמותה",
          field: "nvAssociationName",
          //width: 160,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום חיוב",
          field: "nDebitAmount",
          //width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום ששולם",
          field: "nAmountPaid",
          //width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "יתרה",
          field: "nBalance",
          //width: 120,
          filter: "agTextColumnFilter"
        },
      ];
      this.rowData1 = res;
    });
  }

  clickRefreshData() {
    return this.service.clickRefreshData().then(res => {
    this.getLastDebit();
  })
    .catch(err => { console.log("err-----" + err); return false; })
    
  }
}
