export class Tasks {
    iTaskId: number;
   // iFamilyId: number;
    dTargetDate: Date = new Date ();
    dtLastModifyDate:Date = new Date ();
    nvComment: string;
    bDone: boolean = false;
    nvDone: string;
    iBackgroundColor:number;
    iTargetUserID: number;
    nvCreatedByUser:string;
    nvTargetUser: string;
    nvDoneByUser: string;
}