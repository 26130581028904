import { Component, OnInit } from '@angular/core';
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { ServService } from "../../../app/serv.service";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: 'app-family-tasks-report',
  templateUrl: './family-tasks-report.component.html',
  styleUrls: ['./family-tasks-report.component.css']
})
export class FamilyTasksReportComponent implements OnInit {

  gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnApi: any;
  columnTypes;
  columnChildren;
  private rowData: any[];
  backgroundColor:string;
  constructor(private serv: ServService, private router: Router

  ) {
  }

  ngOnInit() {
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      // domLayout: "forPrint",
      onGridReady: this.onGridReady,
      defaultColDef: {
        resizable: true
    },
    }

    //fill the grid with data
    this.serv
      .GetFamilyTasksReport()
      .then(res => {
        for (let i = 0; i < res.length; i++) {
          if(res[i].dTargetDate!=null){
           res[i].dTargetDate2 = moment(res[i].dTargetDate).format("DD/MM/YYYY");
          //console.log(res[i].dTargetDate);
          }
          if(res[i].dtLastModifyDate!=null){
            res[i].dtLastModifyDate2 = moment(res[i].dtLastModifyDate).format("DD/MM/YYYY");
          //  console.log(res[i].dtLastModifyDate);
           }
        }
        this.rowData = res;
      });

    this.columnChildren = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 70,
        suppressMenu: true,
        suppressFilter: true,
        cellRenderer: function (params) {
          if(params.data.bReadOnly!= true){
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.editTasks(params.data);

              });

            return eDiv;
          }
        }
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך עדכון",
        field: "dtLastModifyDate2",
        width: 100,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "שם מעדכן",
        field: "nvCreatedByUser",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך יעד",
        field: "dTargetDate2",
        width: 100,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "מיועד עבור",
        field: "nvTargetUser",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "פרטים",
        field: "nvComment",
        width: 500,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בוצע",
        field: "nvDone",
        width: 80,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בוצע עי",
        field: "nvDoneByUser",
        width: 80,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    params.api.sizeColumnsToFit();
  }

  onBtExport() {
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "דוח תזכורות"
    };
    this.gridApi.exportDataAsExcel(params);
  }

   dateComparator(date1, date2) {
    if (
      (date1 === null && date2 === null) ||
      (typeof date1 == "undefined" && typeof date2 == "undefined")
    ) {
      return 0;
    }
    if (date1 === null || typeof date1 == "undefined") {
      return -1;
    }
    if (date2 === null || typeof date2 == "undefined") {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }

  filterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();

    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
  }

  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
  }

  editTasks(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId;
   // this.serv.currentFamily = Family;

/*     ParentsListComponent.parentListColState = this.gridOptions.columnApi.getColumnState();
    ParentsListComponent.parentListGroupState = this.gridOptions.columnApi.getColumnGroupState();
    ParentsListComponent.parentListSortState = this.gridOptions.api.getSortModel();
    ParentsListComponent.parentListFilterState = this.gridOptions.api.getFilterModel(); */
    
    this.router.navigateByUrl("/header/families/tabs/family-tasks");
    this.serv.bFromTasks = true;
    this.serv.visible = false;
  }
}
