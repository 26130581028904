
// @Component({
//   selector: 'app-mat-checkbox',
//   templateUrl: './mat-checkbox.component.html',
//   styleUrls: ['./mat-checkbox.component.css']
// })
import { Component } from "@angular/core";
import { ICellRendererAngularComp, AgRendererComponent } from "ag-grid-angular";
import { MatCheckboxService } from "src/app/Additions/mat-checkbox/mat-checkbox/mat-checkbox.service";
@Component({
  selector: "checkbox-cell",
  template: `
        <mat-checkbox [ngModel]="params.data&&params.data.checked" (ngModelChange)="onChange($event)" color="primary"></mat-checkbox>
    `,
  styles: [
    `
            ::ng-deep
            .mat-checkbox-layout {
                /* horizontally align the checkbox */
                width: 100%;
                display: inline-block !important;
                text-align: center;
                margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

                /* vertically align the checkbox when not using the ag-material theme - should be the same as the
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
            }
        `
  ]
})
export class MatCheckboxComponent implements AgRendererComponent, ICellRendererAngularComp {
  params: any;
  public selectedItemes: any[] = [];

  checked: boolean = false;
  constructor(public matCheckboxService: MatCheckboxService) {

    // this.matCheckboxService.selectedItems=[];
  }
  agInit(params: any): void {
    this.selectedItemes = null;
    this.params = params;
    this.checked = this.params.value === "On";
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean) {
    this.checked = checked;
    if (this.params.data) {
      this.params.data.isChecked = checked;
      if (this.matCheckboxService.selectedItems.find(item => item == this.params.data))
        this.matCheckboxService.selectedItems.splice(this.matCheckboxService.selectedItems.indexOf(this.params.data), 1);
      else
        this.matCheckboxService.selectedItems.push(this.params.data);
    }else{
      this.matCheckboxService.selectedItems.forEach(d=>d.isChecked=false);
      this.matCheckboxService.selectedItems=[];
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
