
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LOCALE_TEXT } from '../../app/Additions/constants';
import { ServService } from '../../app/serv.service';
import * as moment from "moment"
import { Component, OnInit, Inject } from '@angular/core';
// import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-mobility-to-associations',
  templateUrl: './mobility-to-associations.html',
  styleUrls: ['./mobility-to-associations.css']
})
export class MobilityToAssociationsComponent implements OnInit {

  sum: number;
  backgroundColor: string;
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;

  iAssociationId: number = 0;
  lFamilies: Array<number>
  constructor(public service: ServService, public dialogRef: MatDialogRef<MobilityToAssociationsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.backgroundColor = this.service.backgroundColor[2];
    this.lFamilies = new Array<number>();

    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive:true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {


    this.GetMobilityToAssociations();

    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      defaultColDef: {
        resizable: true
    },
      onGridReady: this.onGridReady,
    },
      this.columnDefs = [
        // {
        //   headerName: "פתיחה",
        //   field: "nvFamilyName",
        //   width: 120,
        //   suppressFilter: true,
        //   cellRenderer: function (params) {
        //     var eDiv = document.createElement("div");
        //     eDiv.innerHTML = '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
        //     var eButton = eDiv.querySelectorAll('#edit')[0];
        //     eButton.addEventListener('click', function () {
        //       params.context.componentParent.editFamily(params);
        //     });

        //     return eDiv;
        //   }
        // },
        {
          headerName: "שם משפחה",
          field: "nvFamilyName",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "שם אב",
          field: "nvFatherName",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "טלפון",
          field: "nvPhone",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "נייד",
          field: "nvMobile",
          width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "כתובת",
          field: "nvAddress",
          width: 150,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "עיר",
          field: "nvCityName",
          width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "שכונה",
          field: "nvNeighborhood",
          width: 100,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "בית",
          field: "nvNumHouse",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום",
          field: "nAmount",
          width: 100,
          filter: "agTextColumnFilter"
        }]

  };
  UpdMobilityToAssociations() {

    if (this.iAssociationId == 0)
      return;
    this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
      this.lFamilies.push(element.data.iFamilyId)
    });
    this.service.UpdMobilityToAssociations(this.iAssociationId, this.lFamilies).then(res => {
      if (res) {
        this.GetMobilityToAssociations();
      }
    }).catch(err => { console.log("err-----" + err); return false; })

  }
  calcSum() {
    this.sum = 0;
    this.gridOptions.gridApi.forEachNodeAfterFilter((item, index) => {
      this.sum += item.data.nAmount;
    });
  }
  onGridReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;


    params.api.sizeColumnsToFit();

  }
  onFilterModified(event) {
    this.calcSum();
  }


  GetMobilityToAssociations() {

    this.service.GetMobilityToAssociations(this.data[0], this.data[1]).then(res => {

      this.sum = 0;

      if (res) {
        this.rowData = res;
        for (let i = 0; i < res.length; i++) {
          this.sum += res[i].nAmount;
          if (res[i].dtCreateDate != null) {
            res[i].dtCreateDate = moment(res[i].dtCreateDate).format("DD/MM/YYYY");
          } else if (res[i].dDebitDate == null) {
            res[i].dtCreateDate = ""
          }
          if (res[i].dDebitDate != null) {
            res[i].dDebitDate = moment(res[i].dDebitDate).format("DD/MM/YYYY");

          } else if (res[i].dDebitDate == null) {
            res[i].dDebitDate = ""

          }
        }
      }

    }).catch(err => { console.log("err-----" , err); return false; })
  }
  closeDialog() {
    this.dialogRef.close()
  }
}


