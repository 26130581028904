import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Injectable()
export class AppProxy {
  public static baseUrl;
  public static docUrl;
  public static isWait: boolean;
   docUrl = "https://active-smart.co/Ws/Files/";
//http://qa.webit-track.com/SensorsWS/Files/
  constructor(private http: HttpClient, private route: Router, private spinnerService: Ng4LoadingSpinnerService) {
    AppProxy.docUrl = "https://active-smart.co/Ws/Files/";
    AppProxy.baseUrl ='https://active-smart.co/Ws/' ;
    //http://localhost/Smart_QA/Ws/
    //http://qa.active-smart.co/WS/
    //http://new.active-smart.co/ws/
    //https://active-smart.co/Ws/
    //'http://localhost:41525/' //'http://www.webitqa.com/SmartWS_QA/'//  //'https://active-smart.co/Ws/'


    AppProxy.isWait = false;
  }

  private convertData(data, isPost) {
    if (!(data instanceof Object) || data instanceof Date) {
      let prop = data;
      // parse string date
      if (
        isPost === false &&
        (prop instanceof String || typeof prop === "string") &&
        prop.indexOf("/Date(") > -1
      ) {
        let date = prop.substring(
          prop.indexOf("(") + 1,
          prop.indexOf("+") != -1 ? prop.indexOf("+") : prop.indexOf(")")
        );
        prop = new Date(parseInt(date));
      } else if (isPost && prop instanceof Date) {
        // convert to string date
        let d = Date.UTC(
          prop.getFullYear(),
          prop.getMonth(),
          prop.getDate(),
          prop.getHours(),
          prop.getMinutes()
        );
        prop =
          d.toString() === "NaN" || d === NaN
            ? null
            : "/Date(" +
            Date.UTC(
              prop.getFullYear(),
              prop.getMonth(),
              prop.getDate(),
              prop.getHours(),
              prop.getMinutes()
            ) +
            ")/";
      }
      return prop;
    }
    // parse array / object
    let isArr = data instanceof Array;
    let arrayData = [];
    let objectData = {};
    if (data) {
      Object.keys(data).forEach(key => {
        // dictionary
        if (
          !isPost &&
          isArr &&
          data[key] &&
          Object.keys(data[key]).length === 2 &&
          data[key].Key &&
          data[key].Value != null
        ) {
          arrayData[data[key].Key] = this.convertData(data[key].Value, isPost);
        } else if (isArr) {
          // array
          arrayData.push(this.convertData(data[key], isPost));
        } else {
          // object
          objectData[key] = this.convertData(data[key], isPost);
        }
      });
    }
    return isArr ? arrayData : objectData;
  }
  get(url: string, data): Promise<any> {
    this.spinnerService.show();
    return this.http
      .get(`${AppProxy.baseUrl + 'Service.svc/'}${url}`, data)
      .toPromise()
      .then(result => {
        this.spinnerService.hide();
        return this.convertData(result, false);
      });

  }

  post(url: string, data): Promise<any> {

    console.log(url + "--" + JSON.stringify(this.convertData(data, true)) + "--");
    this.spinnerService.show();
    return this.http.post(`${AppProxy.baseUrl + 'Service.svc/'}${url}`, this.convertData(data, true)).toPromise().then(result => {
      this.spinnerService.hide();

      if (result && result == 'GuideExpired')
        this.route.navigate(['/login']);
      return this.convertData(result, false);

    }).catch(result => {
      if (result && result.error == 'GuideExpired')
        this.route.navigate(['/login']);
      this.spinnerService.hide();
    }
    )

  }


}
