import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ServService } from "../../../app/serv.service";

@Component({
  selector: "app-charts",
  templateUrl: "./charts.component.html",
  styleUrls: ["./charts.component.css"],
})
export class ChartsComponent implements OnInit {
  currentComponent: any;
  backgroundColor: string;

  temp: boolean = false;
  isAllStatusesChecked: boolean = false;
  isAllBranchesChecked: boolean = false;
  lStatus: Array<number>;
  isAllChecked: boolean = false;
  constructor(public serv: ServService, private router: Router) {
    this.backgroundColor = this.serv.backgroundColor[5];
    this.lStatus = new Array<number>();


  }

  ngOnInit() {
    this.router.navigate(["/header/charts/credit-percent"]);

    if (localStorage.getItem("familyStatuses") !== null) {
      this.serv.lStatus = this.lStatus = JSON.parse(
        localStorage.getItem("familyStatuses")
      );
    }
  }

  public onRouterOutletActivate(event: any) {
    this.currentComponent = event;
    console.log(this.currentComponent);
  }
  onSelectYear() {
    this.currentComponent.refresh(this.lStatus);
  }

  onSelectStatus(event) {
    var tempArr: Array<number> = new Array<number>();

    //אם לחצן הכל לחוץ אך הוסר סטטוס אחד, יש להסיר את הלחיצה מכפתור הכל
    if (
      event.value[0] == "0" &&
      this.lStatus.length == this.serv.lFamilyStatusType.length &&
      this.temp
    ) {
      this.temp = false;
      this.isAllChecked = false;
      for (let i = 1; i < this.lStatus.length; i++) {
        if (this.lStatus[i] > 0) {
          tempArr.push(this.lStatus[i]);
        }
      }
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
      this.serv.lStatus = this.lStatus = tempArr;
    }
    localStorage.setItem("familyStatuses", JSON.stringify(this.lStatus));

    this.currentComponent.refresh(this.lStatus);
  }
  onClickAll() {
    if (!this.isAllChecked) {
      this.isAllChecked = true;
      this.temp = true;
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
      for (let i = 0; i < this.serv.lFamilyStatusType.length; i++) {
        this.lStatus[i] = this.serv.lFamilyStatusType[i].iId;
      }
      this.lStatus.push(0);
    } else if (this.isAllChecked) {
      this.temp = false;
      this.isAllChecked = false;
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
    }
    localStorage.setItem("familyStatuses", JSON.stringify(this.lStatus));

    setTimeout(() => {
      this.currentComponent.refresh(this.lStatus);
    }, 100);

    this.serv.lStatus = this.lStatus;
  }

  onSelectBranch() {
    this.currentComponent.refresh(this.lStatus);
  }
  onClickAllBranches(){
    this.serv.iBranchId=0;
     this.currentComponent.refresh(this.lStatus);
  }

}
