import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServService } from '../../../app/serv.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  name = 'ng2-ckeditor';
  mycontent: string;
  log: string = '';
  @ViewChild("myckeditor", { static: false }) ckeditor: any;
  currentComponent: any;
  form: FormGroup
  updateclass = false
  childrenlist = true
  backgroundColor: string;
  lStatus: Array<number>
  temp: boolean = false;
  isAllChecked: boolean = false;
  ngOnDestroy() {

    // this.form = new FormGroup({
    //   iYearId:new FormControl(),
    // })

  }


  constructor(public serv: ServService, private router: Router) {
    this.backgroundColor = this.serv.backgroundColor[3];
    this.lStatus = new Array<number>()
  }


  ngOnInit() {
    if (this.serv.bfromParentsAndChild == false && this.serv.bfromParentsBalance == false && this.serv.bfromParentsList == false)
      this.router.navigate(['/header/reports/children-list'])
    if (localStorage.getItem("familyStatuses") !== null) {
      this.serv.lStatus = this.lStatus = JSON.parse(localStorage.getItem("familyStatuses"));
    }
  }

  public onRouterOutletActivate(event: any) {
    this.currentComponent = event;
    console.log(this.currentComponent)
  }

  onSelectYearInReport() {
    this.currentComponent.getReport(this.lStatus);
  }
  onSelectStatus(event) {
    var tempArr: Array<number> = new Array<number>();

    //אם לחצן הכל לחוץ אך הוסר סטטוס אחד, יש להסיר את הלחיצה מכפתור הכל 
    if (event.value[0] == '0' && this.lStatus.length == this.serv.lFamilyStatusType.length && this.temp) {
      this.temp = false
      this.isAllChecked = false
      for (let i = 1; i < this.lStatus.length; i++) {
        if (this.lStatus[i] > 0) {
          tempArr.push(this.lStatus[i])
        }
      }
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length)
      this.serv.lStatus = this.lStatus = tempArr
    }
    localStorage.setItem(
      "familyStatuses",
      JSON.stringify(this.lStatus)
    );

    this.currentComponent.getReport(this.lStatus);
  }
  onClickAll() {

    if (!this.isAllChecked) {
      this.isAllChecked = true
      this.temp = true
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
      for (let i = 0; i < (this.serv.lFamilyStatusType.length); i++) {
        this.lStatus[i] = this.serv.lFamilyStatusType[i].iId
      }
      this.lStatus.push(0)
    }
    else if (this.isAllChecked) {
      this.temp = false
      this.isAllChecked = false
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length)
    }
    localStorage.setItem(
      "familyStatuses",
      JSON.stringify(this.lStatus)
    );

    setTimeout(() => {
      this.currentComponent.getReport(this.lStatus);
    }, 100);

    this.serv.lStatus = this.lStatus;
  }
}