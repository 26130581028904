import { CanLoad, CanActivate } from "@angular/router";
import {
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";

import { Injectable } from "@angular/core";
import { ServService } from "./serv.service";
@Injectable()
export class CanActivateComponent implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.load(route);
  }

  constructor(private service: ServService) { }
  load(route): Promise<boolean> {

    if (localStorage.getItem("familyStatuses") !== null && localStorage.getItem("familyStatuses") !=="undefined") {
      this.service.default1.lFamilyStatus = JSON.parse(localStorage.getItem("familyStatuses"));

    }
    if (localStorage.getItem("dDebitFamilyFromDate") !== null && localStorage.getItem("familyStatuses") !=="undefined") {
      this.service.default1.dDebitFamilyFromDate = JSON.parse(localStorage.getItem("dDebitFamilyFromDate"));

    }
    if (localStorage.getItem("user") !== null && localStorage.getItem("familyStatuses") !=="undefined") {
      this.service.user = JSON.parse(localStorage.getItem("user"));

    }
    if (location.hash.indexOf('parentDetails') != -1 || location.hash.indexOf('children-details') != -1 ||
      location.hash.indexOf('last-debits') != -1 || location.hash.indexOf('details-payment') != -1
      || location.hash.indexOf('family-credit') != -1 || location.hash.indexOf('family-contacts') != -1) {
        this.service.visible = false;
      if (localStorage.getItem("currentiFamilyId") !== null) {
        this.service.currentiFamilyId = JSON.parse(localStorage.getItem("currentiFamilyId"));}
        if (localStorage.getItem("family") !== null) {
          this.service.currentFamily = JSON.parse(localStorage.getItem("family"));
      }
    }
    return this.service
      .GetCodeTable().then(

        res => {
          this.service.GetDefault().then(res=>{
            if (res) {
              return true;
            }
          }).catch(err => {
            return false;
          });
          return true; }
      )

      .catch(err => {
        return false;
      });


  }
}
