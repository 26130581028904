import { Component, OnInit, Inject } from '@angular/core';
import { ServService } from '../../app/serv.service';
import { DebitDetailsToMonth } from '../../Models/DebitDetailsToMonth';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field';
import { LOCALE_TEXT } from '../../app/Additions/constants';

import "ag-grid-enterprise";
import { GridApi } from "ag-grid/dist/lib/gridApi";
import { CellEditorFactory } from 'ag-grid';

@Component({
  selector: 'app-debit-details-to-month',
  templateUrl: './debit-details-to-month.component.html',
  styleUrls: ['./debit-details-to-month.component.css']
})
export class DebitDetailsToMonthComponent implements OnInit {

  // items:DebitDetailsToMonth[]=[];

  sumTotal: number = 0;
  public column;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;
  backgroundColor:string
  debitDate:Date = new Date();

  constructor(public service: ServService, public dialogRef: MatDialogRef<DebitDetailsToMonthComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.backgroundColor = this.service.backgroundColor[0];
this.debitDate = data[0];
    // this.items[0]=1;
    // this.items[1]=1;


  }

  ngOnInit() {

    this.gridOptions = {
      localeText: { LOCALE_TEXT },
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      enableRtl: true,
      // onGridReady: this.onGridReady,
    },

      this.column = [

        {
          headerName: "שם פרטי",
          field: "nvFirstName",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סניף",
          field: "nvBranchName",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "כתה",
          field: "nvLevel",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "עבור",
          field: "nvRateType",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "שכר לימוד",
          field: "nTuition",
          width: 150,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "הנחה",
          field: "nDiscountAmount",
          width: 150,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "הסעה",
          field: "nTransportation",
          width: 150,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "תעריף נוסף",
          field: "nFood",
          width: 150,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "חיובים נוספים",
          field: "nAdditional",
          width: 150,
          filter: "agNumberColumnFilter"
        }, 
        {
          headerName: "סוג חיוב",
          field: "iDebitType",
          width: 150,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "סהכ לתשלום",
          field: "nTotal",
          width: 150,
          filter: "agNumberColumnFilter"
        }]


    this.getDetailsToMonth();
  }

  getDetailsToMonth() {
    
    this.service.GetDetailsToMonth(this.service.user.iUserId, this.service.currentiFamilyId,this.debitDate).then(res => {
      if (res) {
        this.rowData = res
        for (let i = 0; i < this.rowData.length; i++) {
          this.sumTotal += this.rowData[i].nTotal
        }

      }

    }).catch(err => { console.log("err-----" + err); return false; })
  }

  closeDialog(){
    this.dialogRef.close()
  }

}
