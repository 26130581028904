export class Rate{
    iRateId:number=-1
    iRateTypeId:number
    nvRateName:string=undefined
    nRate:number
    nSecondaryRate:number
    iAssociationId:number
    nvAssociationName:string=undefined
    nFixedAmount:number
    dUntilDate:Date=null
}

