import { Component, OnInit } from '@angular/core';
import { ServService } from '../../../app/serv.service';
import { LOCALE_TEXT } from '../../../app/Additions/constants';
import { Router } from '@angular/router';
import * as moment from "moment"
import { pdfByClass, levelInBranch } from '../children-list/children-list.component';
import { ReportsDet } from 'src/Models/ReportsDet';
import { AppProxy } from 'src/app/app.proxy';

@Component({
  selector: 'app-parents-and-children',
  templateUrl: './parents-and-children.component.html',
  styleUrls: ['./parents-and-children.component.css']
})
export class ParentsAndChildrenComponent implements OnInit {
  backgroundColor: string;
  private column;
  private rowData;
  public gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private defaultColDef;
  private pivotMode;

  public static parentsAndChildrenFilterState=null;
  public static parentsAndChildrenSortState=null;
  public static parentsAndChildrenGroupState=[];
  public static parentsAndChildrenColState=null;

  constructor(public serv: ServService, private router: Router) { }
  ngOnInit() {
    this.serv.bfromParentsAndChild = false;
    this.serv.visible = true;
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      enableCharts: true,
      defaultColDef: {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true
    },
      onGridReady: this.onGridReady
    };

    this.pivotMode=true;
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
    this.defaultColDef = {
      enableValue: true,
    };
    this.column = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 80,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML = '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll('#edit')[0];
          eButton.addEventListener('click', function () {
            params.context.componentParent.editFamily(params.data);
          });
          return eDiv;
        }
      },
      {
        headerName: "שורה", field: "indexRow", cellRenderer: function (params) {
          let z = '<p>' + (params.node.rowIndex + 1) + '</p>';
          return z;
        },
        // width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה",
        field: "nvLastName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האב",
        field: "nvFirstNameF",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אב",
        field: "nvIdentityF",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג מ אב",
        field: "iIdentityTypeF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה אב",
        field: "dBirthDateF",
        // width: 120,
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceF",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת עבודה",
        field: "nvWorkAddressF",
        width: 135,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם משפחה אם",
        field: "nvLastNameM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האם",
        field: "nvFirstNameM",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אם",
        field: "nvIdentityM",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג מ אם",
        field: "iIdentityTypeM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה אם",
        field: "dBirthDateM",
        // width: 120,
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceM",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת עבודה",
        field: "nvWorkAddressM",
        width: 135,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        // width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קהילה",
        field: "nvCommunity",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },   
      {
        headerName: "הערות משפחה",
        field: "nvFamilyComments",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'מספר ילדים',
        field: "iChildrenCount",
        width: 110,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מיקוד",
        field: "nvZipCode",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "דואל",
        field: "nvEmail",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מספר תלמידים",
        field: "iNumChildren",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "יתרה לתשלום",
        field: "nBalance",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הו''ק",
        field: "isDD",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: 'אשראי',
        field: "isCC",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "יום חיוב",
        field: "nvDebitDay",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },        
      {
        headerName: "שם פרטי",
        field: "nvFirstName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם חיבה",
        field: "nvNickname",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'מיקום במשפחה',
        field: "iFamilyLocation",
        width: 110,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה",
        field: "dBirthDate",
        // width: 150,
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "מ.זהות",
        field: "nvIdentity",
        // width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג מ זהות",
        field: "iIdentityType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה עברי",
        field: "nvJewBirthDate",
        // width: 130,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "גיל",
        field: "iAge",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "קופ'ח",
        field: "nvKupatCholimName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.רישום",
        field: "dRegisteredDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "ת.כניסה",
        field: "dBeginDate",
        // width: 150,
        type: "dateColumn",
        sortable: true
      },

      {
        headerName: 'שכ"ל',
        field: "nTuitionAmount",
        // width: 110,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הנחה",
        field: "nDiscountAmount",
        // width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "זיכוי",
        field: "nRefund",
        // width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף נוסף",
        field: "nMealsAmount",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה",
        field: "nTransAmount",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף הסעה",
        field: "nvTranspRateName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'חיובים נוספים',
        field: "nAmount",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ס'ה",
        field: "nTotal",
        // width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סניף",
        field: "nvBranchName",
        // width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "סטטוס",
        field: "nvStatusName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה הלוך",
        field: "nvTranspGoType",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה הלוך",
        field: "nvStopNumGo",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה חזור ",
        field: "nvTranspReturnType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה חזור",
        field: "nvStopNumRetern",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה שישי",
        field: "nvTranspReturnFriType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה שישי",
        field: "nvStopNumFri",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה מיוחדת",
        field: "nvTranspSpecialType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה מיוחדת",
        field: "nvStopNumSpecial",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קופח",
        field: "nvKupatCholimName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הצהרת בריאות",
        field: "nvHealthDeclaration",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'הערות',
        field: "nvComments",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות1",
        field: "nvComments1",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
    this.getReport(JSON.parse(localStorage.getItem("familyStatuses")));
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(ParentsAndChildrenComponent.parentsAndChildrenFilterState);
  };
  rowDataChanged(event){
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(ParentsAndChildrenComponent.parentsAndChildrenFilterState);
  }
  filterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();

    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  sortChanged() {
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: 'פרטי תלמידים',
    //   sheetName: 'xls'
    // };
    // this.gridOptions.api.exportDataAsCsv(params);
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: 'פרטי תלמידים'
    };
    this.gridApi.exportDataAsExcel(params);
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();

    if (this.serv != undefined) {
      if(ParentsAndChildrenComponent.parentsAndChildrenColState)
    this.gridOptions.columnApi.setColumnState(ParentsAndChildrenComponent.parentsAndChildrenColState);
    if(ParentsAndChildrenComponent.parentsAndChildrenGroupState[0])
    this.gridOptions.columnApi.setColumnGroupState(ParentsAndChildrenComponent.parentsAndChildrenGroupState);
    if(ParentsAndChildrenComponent.parentsAndChildrenSortState)
    this.gridOptions.api.setSortModel(ParentsAndChildrenComponent.parentsAndChildrenSortState);
    if(ParentsAndChildrenComponent.parentsAndChildrenFilterState)
    this.gridOptions.api.setFilterModel(ParentsAndChildrenComponent.parentsAndChildrenFilterState);
  }
    //  params.api.sizeColumnsToFit();
  }
  getReport(lStatus) {
    this.serv.GetChildrenReport(lStatus).then(res => {
      for (let i = 0; i < (res as any[]).length; i++) {
        res[i].dBeginDate = res[i].dBeginDate != null ? moment(res[i].dBeginDate).format("DD/MM/YYYY") : "";
        res[i].dRegisteredDate = res[i].dRegisteredDate != null ? moment(res[i].dRegisteredDate).format("DD/MM/YYYY") : "";
        res[i].dBirthDate = res[i].dBirthDate != null ? moment(res[i].dBirthDate).format("DD/MM/YYYY") : "";
        res[i].dBirthDateF = res[i].dBirthDateF != null ? moment(res[i].dBirthDateF).format("DD/MM/YYYY") : "";
        res[i].dBirthDateM = res[i].dBirthDateM != null ? moment(res[i].dBirthDateM).format("DD/MM/YYYY") : "";
      }
      this.rowData = res;
      if(this.gridOptions.api)
       this.gridOptions.api.setFilterModel(ParentsAndChildrenComponent.parentsAndChildrenFilterState);
    });
  }
  editFamily(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId;
    this.serv.currentFamily = Family;

    ParentsAndChildrenComponent.parentsAndChildrenColState = this.gridOptions.columnApi.getColumnState();
    ParentsAndChildrenComponent.parentsAndChildrenGroupState = this.gridOptions.columnApi.getColumnGroupState();
    ParentsAndChildrenComponent.parentsAndChildrenSortState = this.gridOptions.api.getSortModel();
    ParentsAndChildrenComponent.parentsAndChildrenFilterState = this.gridOptions.api.getFilterModel();

    this.router.navigateByUrl('/header/families/tabs/parentDetails');
    this.serv.bfromParentsAndChild = true;
    this.serv.visible = false;
  }

  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
  }

  lReportsDet: Array<ReportsDet> = new Array<ReportsDet>();
  ReportsDet: ReportsDet;
  lpdfByClass: Array<pdfByClass>;
  pPdfByClass: pdfByClass;
  lLevelInBranch: Array<levelInBranch> = new Array<levelInBranch>();
  lLevel: Array<string> = new Array<string>();

  printToPDF() {
    this.lLevelInBranch = new Array<levelInBranch>();
    this.lLevel = new Array<string>();
    this.lpdfByClass = new Array<pdfByClass>();
    let lstColumn;
    this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
      
      if (!this.lLevel.includes(element.data.nvLevel)){
        let level:levelInBranch=new levelInBranch()
        level.nvBranch=element.data.nvBranchName;
        level.nvLevel=element.data.nvLevel;
         this.lLevelInBranch.push(level);
        this.lLevel.push(element.data.nvLevel);
      }
    });


    if (this.gridColumnApi) {
      lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDet = new Array<ReportsDet>();
      lstColumn.forEach((col, index) => {
        this.ReportsDet = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDet.nFieldWidth = col.actualWidth;
          this.ReportsDet.nvFieldName = col.colId;
          this.ReportsDet.nvFieldTitle = col.colDef.headerName;
          this.lReportsDet.push(this.ReportsDet);
        }
      });
    }

    this.lLevelInBranch.forEach(elementLevel => {
      var tempRowData:any[]=[]
      this.pPdfByClass = new pdfByClass();
      //this.pPdfByClass.title = ' דוח תלמידים כיתה ' + elementLevel;
      this.pPdfByClass.title = "<h1 style='text-align:center; font-weight: bold; font-size: 25px;'> דוח הורים ותלמידים סניף   " + elementLevel.nvBranch+" כיתה  "+elementLevel.nvLevel;

      if (this.gridColumnApi) {
        if (this.lReportsDet)
        this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
          if(element.data.nvLevel==elementLevel.nvLevel)
          tempRowData.push(element);
        });
          var string = this.createDataTable(this.lReportsDet, tempRowData);
        this.pPdfByClass.strTable = string;
        this.lpdfByClass.push(this.pPdfByClass);

      }

    });
    this.serv.PrintToPDFByClass(this.lReportsDet, this.lpdfByClass).then(res => {
      console.log(res);
      var link = document.createElement("a");
      link.download = res;
      link.href = AppProxy.baseUrl + "Files/" + res;
      window.open(link.href);
    });
  }

  createDataTable(lReportsDet, data) {
    var string =
      '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

    lReportsDet.forEach((col, colIndex) => {
      string +=
        '<xs:element name="a' +
        colIndex +
        '" type="xs:string" minOccurs="0"/>';
    });

    string +=
      '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
    if (data)
      data.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

          lReportsDet.forEach((col, colIndex) => {
            let dataIndexPlusOne = dataIndex + 1;
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndexPlusOne + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" +
                element.data[col.nvFieldName] +
                "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
    string += "</DocumentElement></diffgr:diffgram></DataTable>";
    return string;
  }


}
