import { User } from './../../../Models/User';
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Form, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ServService } from "../../../app/serv.service";
import { Family } from "../../../Models/Family";
import { ChildrenRoport } from "../../../Models/ChildrenReports";
import * as moment from "moment";
import "ag-grid-enterprise";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { UpdateChildrenComponent } from "../../update-children/update-children.component";
import { ReportsDet } from "src/Models/ReportsDet";
import { AppProxy } from "src/app/app.proxy";
import { ColDef, GridReadyEvent, SideBarDef } from 'ag-grid-community';

@Component({
  selector: "app-children-list",
  templateUrl: "./children-list.component.html",
  styleUrls: ["./children-list.component.css"]
})
export class ChildrenListComponent implements OnInit {
  dialogRef: any;
  backgroundColor: string;
  public column;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;
  public visible: boolean;
  public sideBar: SideBarDef | string | string[] | boolean | null = 'filters';
  public childrenReport: Array<ChildrenRoport>;
  public defaultColDef;
  private pivotMode;
  currentiFamilyIdTemp;
  numRows=0;
  sumTotal=0;

  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp;
  }

  constructor(
    public serv: ServService,
    private route: Router,
    private updateChildDailog: MatDialog
  ) {
    this.backgroundColor = this.serv.backgroundColor[3];
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {
    this.serv.visible = true;
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      defaultColDef: {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true
    },
      enableRtl: true,
      enableCharts: true,
      // showToolPanel: true,
      // toolPanel:'columns',
      onGridReady: this.onGridReady,
      sideBar:'filters',
    };
    this.sideBar = "filters";
    this.defaultColDef = {
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true
    };
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
    this.pivotMode=true;
    this.column = [
      {
        headerName: "פתיחה",
        field: "iChildId",
        width: 90,
        suppressMenu: true,
        suppressFilter: true,
        cellRenderer: function (params) {
          if(params.data.bReadOnly!= true){
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.updateDetails(params.data);

              });

            return eDiv;
          }
        }
      },
      {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה ",
        field: "nvLastName",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם פרטי",
        field: "nvFirstName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האב",
        field: "nvFirstNameF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סניף",
        field: "nvBranchName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.זהות",
        field: "nvIdentity",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג מ זהות",
        field: "iIdentityType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה",
        field: "dBirthDate",
        width: 120,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "ת.לידה עברי",
        field: "nvJewBirthDate",
        width: 130,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "גיל",
        field: "iAge",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קהילה",
        field: "nvCommunity",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם חיבה",
        field: "nvNickname",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'מספר ילדים',
        field: "iChildrenCount",
        width: 125,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: 'מיקום במשפחה',
        field: "iFamilyLocation",
        width: 147,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.רישום",
        field: "dRegisteredDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "ת.כניסה",
        field: "dBeginDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "ת.יציאה",
        field: "dEndDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: 'שכ"ל',
        field: "nvTuitionRateName",
        width: 110,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'סכום שכ"ל',
        field: "nTuitionAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סך הנחה",
        field: "nDiscountAmount",
        width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },    
      {
        headerName: "אחוז הנחה",
        field: "nDiscountPerc",
        width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הנחה קבועה",
        field: "nvDiscSet",
        width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "זיכוי",
        field: "nRefund",
        width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "נוסף",
        field: "nvMealsRateName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום נוסף",
        field: "nMealsAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה",
        field: "nvTranspRateName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום הסעה",
        field: "nTransAmount",
        width: 125,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "חיובים נוספים",
        field: "nAmount",
        width: 135,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ס'ה",
        field: "nTotal",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatusName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה הלוך",
        field: "nvTranspGoType",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה הלוך",
        field: "nvStopNumGo",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה חזור ",
        field: "nvTranspReturnType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה חזור",
        field: "nvStopNumRetern",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה שישי",
        field: "nvTranspReturnFriType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה שישי",
        field: "nvStopNumFri",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה מיוחדת",
        field: "nvTranspSpecialType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס תחנה מיוחדת",
        field: "nvStopNumSpecial",
        width: 121,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קופח",
        field: "nvKupatCholimName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הצהרת בריאות",
        field: "nvHealthDeclaration",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComments",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות1",
        field: "nvComments1",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
    this.getReport(JSON.parse(localStorage.getItem("familyStatuses")));
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    //  params.api.sizeColumnsToFit();

  }

  filterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();

    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
     filteredRows.forEach(t=>{
       this.sumTotal+=t.data.nTotal;
       this.numRows++;
     })

  }

  sortChanged() {
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }

  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    filteredRows.forEach(t=>{
      this.sumTotal+=t.data.nTotal;
      this.numRows++;
    })
  }

  dateComparator(date1, date2) {
    if (
      (date1 === null && date2 === null) ||
      (typeof date1 == "undefined" && typeof date2 == "undefined")
    ) {
      return 0;
    }
    if (date1 === null || typeof date1 == "undefined") {
      return -1;
    }
    if (date2 === null || typeof date2 == "undefined") {
      return 1;
    }
    // if(date1&&date2){
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    if (cellDate1 === null && cellDate2 === null) {
      return 0;
    }
    if (cellDate1 === null) {
      return -1;
    }
    if (cellDate2 === null) {
      return 1;
    }
    return cellDate1.getTime() - cellDate2.getTime();

    // }
  }
  getReport(lStatus) {
      this.numRows=0;
      this.sumTotal=0;  
       // alert("children")
    this.serv.GetChildrenReport(lStatus).then(res => {
      for (let i = 0; i < (res as any[]).length; i++) {
        if (res[i].dBeginDate != null) {
          res[i].dBeginDate = moment(res[i].dBeginDate).format("DD/MM/YYYY");
        } else {
          res[i].dBeginDate = null;
        }
        if (res[i].dEndDate != null) {
          res[i].dEndDate = moment(res[i].dEndDate).format("DD/MM/YYYY");
        } else {
          res[i].dEndDate = null;
        }
        if (res[i].dBirthDate != null) {
          res[i].dBirthDate = moment(res[i].dBirthDate).format("DD/MM/YYYY");
        } else {
          res[i].dBirthDate = "";
        }
        if (res[i].dRegisteredDate != null) {
          res[i].dRegisteredDate = moment(res[i].dRegisteredDate).format("DD/MM/YYYY");
        } else {
          res[i].dRegisteredDate = "";
        }
      
         this.sumTotal+=res[i].nTotal;
         this.numRows++;        
      }
      this.childrenReport = res;
      this.rowData = this.childrenReport;
    });
  }
  updateDetails(params) {
    this.dialogRef = this.updateChildDailog.open(UpdateChildrenComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px",
      data: { iChildId: params.iChildId }
    });
  }
  editChild() {
    // this.serv.currentiFamilyId = Family.iFamilyId
    // this.serv.currentFamily = Family;
    // this.router.navigateByUrl('/header/families/tabs/parentDetails');
    // this.serv.visible = false
  }
  onBtExport() {

    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "פרטי תלמידים"
    };
    this.gridApi.exportDataAsExcel(params);
  }

  lReportsDet: Array<ReportsDet> = new Array<ReportsDet>();
  ReportsDet: ReportsDet;
  lpdfByClass: Array<pdfByClass>;
  pPdfByClass: pdfByClass;
  lLevelInBranch: Array<levelInBranch> = new Array<levelInBranch>();
  lLevel: Array<string> = new Array<string>();

  printToPDF() {
    this.lLevelInBranch = new Array<levelInBranch>();
    this.lLevel = new Array<string>();
    this.lpdfByClass = new Array<pdfByClass>();
    let lstColumn;
    this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
      
      if (!this.lLevel.includes(element.data.nvLevel)){
        let level:levelInBranch=new levelInBranch()
        level.nvBranch=element.data.nvBranchName;
        level.nvLevel=element.data.nvLevel;
         this.lLevelInBranch.push(level);
        this.lLevel.push(element.data.nvLevel);
      }
    });

    if (this.gridColumnApi) {
      lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDet = new Array<ReportsDet>();
      lstColumn.forEach((col, index) => {
        this.ReportsDet = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDet.nFieldWidth = col.actualWidth;
          this.ReportsDet.nvFieldName = col.colId;
          this.ReportsDet.nvFieldTitle = col.colDef.headerName;
          this.lReportsDet.push(this.ReportsDet);
        }
      });
    }

    this.lLevelInBranch.forEach(elementLevel => {
      var tempRowData:any[]=[]
      this.pPdfByClass = new pdfByClass();
      this.pPdfByClass.title = "<h1 style='text-align:center; font-weight: bold; font-size: 25px;'> דוח תלמידים סניף   " + elementLevel.nvBranch+" כיתה "+elementLevel.nvLevel;

      if (this.gridColumnApi) {
        if (this.lReportsDet)
        this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
          if(element.data.nvLevel==elementLevel.nvLevel)
          tempRowData.push(element);
        });
          var string = this.createDataTable(this.lReportsDet, tempRowData);
        this.pPdfByClass.strTable = string;
        this.lpdfByClass.push(this.pPdfByClass);

      }

    });
    this.serv.PrintToPDFByClass(this.lReportsDet, this.lpdfByClass).then(res => {
      console.log(res);
      var link = document.createElement("a");
      link.download = res;
      link.href = AppProxy.docUrl/* baseUrl + "Files/" */ + res;
      window.open(link.href);
    });
  }

  createDataTable(lReportsDet, data) {
    var string =
      '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

    lReportsDet.forEach((col, colIndex) => {
      string +=
        '<xs:element name="a' +
        colIndex +
        '" type="xs:string" minOccurs="0"/>';
    });

    string +=
      '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
    if (data)
      data.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

          lReportsDet.forEach((col, colIndex) => {
            let dataIndexPlusOne = dataIndex + 1;
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndexPlusOne + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" +
                element.data[col.nvFieldName] +
                "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
    string += "</DocumentElement></diffgr:diffgram></DataTable>";
    return string;
  }

}


export class pdfByClass {
  strTable: string;
  // lReportsDet: Array<ReportsDet>;
  title: string;
}

export class levelInBranch {
  nvLevel: string;
  // lReportsDet: Array<ReportsDet>;
  nvBranch: string;
}