import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { ServService } from "../../app/serv.service";
import {
  UpdateChildDetails,
  DebitAddition
} from "../../Models/UpdateChildrenDetails";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatTableDataSource, MatTable } from "@angular/material/table";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CodeTable } from "../../Models/CodeTable";
import { dailog } from "../../app/Additions/dailog/dailog";
import { AppProxy } from "../../app/app.proxy";
import { FileObj } from "../../Models/FileObj";
import { HebrewDateService } from "../../hebrew-date.service";
import { WindowRef } from "../../app/serv.service";
import { IsraelIdValidationService } from '../../Models/IsraelIdValidationService';

@Component({
  selector: "app-update-children",
  templateUrl: "./update-children.component.html",
  styleUrls: ["./update-children.component.css"]
})
export class UpdateChildrenComponent implements OnInit {
  backgroundColor: string;
  @ViewChild("table", { static: false }) myTable: MatTable<any>;
  @ViewChild("f", { static: false }) myNgForm;

  lTuitionRate: CodeTable;
  date = new Date();
  alertResponse = "";
  displayAlert = false;
  filedsNotFull = false;
  isIdValid = false;
  typingTimer: any;
  isSelectedBranch: boolean = false;
  lClasses: Array<CodeTable>;
  lTransportRouteForth: Array<CodeTable>;
  lTransportRouteBack: Array<CodeTable>;
  lTransportRouteFriday: Array<CodeTable>;
  lTransportRouteSpecial: Array<CodeTable>;
  udChildDetails: UpdateChildDetails;
  flagIsEdit = false;
  debit: DebitAddition;
  form: FormGroup;
  formPopup: FormGroup;
  displayedColumns = [
    "nAmount",
    "iPaymentNum",
    "dDebitMonth",
    "nvComment",
    "button"
  ];
  disable: boolean = true;
  Id: number = 3;
  Passport: number = 4;
  isVisible: boolean;
  debitAdittion: DebitAddition;
  isVisibleGrid: boolean = false;
  isVisibleAddDebitBtn: boolean = false;
  fullFields = 0;
  isVisible2 = false;
  isVisibleFields = false;
  fromDate: Date = null;
  toDate: Date = null;
  rateSlct: string = "";
  isVisible3 = false;
  pattern = "[0-9]*";
  date1 = new Date();
  fileNameDialogRef: MatDialogRef<dailog>;
  lDebitAddition: Array<DebitAddition>;
  ImageUrl: any;
  nvImage: any;
  base64: string;
  FamilyCodeTable: CodeTable[];
  x = 1000;
  y = 1500;

  constructor(
    public serv: ServService,
    public route: Router,
    public hebrewDateService: HebrewDateService,
    public dialogRef: MatDialogRef<UpdateChildrenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dailog: MatDialog,
    private winRef: WindowRef,
    private idValidationService: IsraelIdValidationService
  ) {
    this.isVisible = false;
    this.debitAdittion = new DebitAddition();
    this.backgroundColor = serv.backgroundColor[0];
    this.udChildDetails = new UpdateChildDetails();
    this.lClasses = new Array<CodeTable>();
    this.lTransportRouteForth = new Array<CodeTable>();
    this.lTransportRouteBack = new Array<CodeTable>();
    this.lTransportRouteFriday = new Array<CodeTable>();
    this.lTransportRouteSpecial = new Array<CodeTable>();
    this.date = new Date();
    this.lTuitionRate = new CodeTable();
  }

  ngOnInit() {
    this.udChildDetails.dRegisteredDate = this.date;
    this.udChildDetails.identity = new FileObj();
    this.toDate = this.date;
    this.date1.setMonth(this.date1.getMonth() - 1);
    // console.log("date " + this.date1);
    this.fromDate = this.date1;
    this.GetTuitionRateCodeTable();

    this.form = new FormGroup({
      nvFirstName: new FormControl("", Validators.required),
      dBirthDate: new FormControl(),
      nvJewBirthDate: new FormControl(),
      nvIdentity: new FormControl(),
      dBeginDate: new FormControl(),
      dEndDate: new FormControl(),
      iClassId: new FormControl("", Validators.required),
      iBranchId: new FormControl("", Validators.required),
      iStatusType: new FormControl("", Validators.required),
      nDiscountPerc: new FormControl(""),
      nDiscountAmount: new FormControl(""),
      bDiscSet: new FormControl(),
      iTranspRateId: new FormControl(),
      nTransAmount: new FormControl ("", Validators.pattern("-?[0-9]*")) ,
      iMealsRateId: new FormControl(),
      nMealsAmount: new FormControl("", Validators.pattern("-?[0-9]*")),
      iTranspGoType: new FormControl(),
      iTranspReturnType: new FormControl(),
      iTranspReturnFriType: new FormControl(),
      iTranspSpecialType: new FormControl(),
      nvStopNumFri: new FormControl(),
      nvStopNumGo: new FormControl(),
      nvStopNumRetern: new FormControl(),
      nvStopNumSpecial: new FormControl(),
      iIdentityType: new FormControl("", Validators.required),
      iTuitionRateId: new FormControl(),
      nTuitionAmount: new FormControl("", Validators.pattern("[0-9]*")),
      dRegisteredDate: new FormControl(),
      iKupatCholim: new FormControl(),
      bHealthDeclaration: new FormControl(),
      nvInstitutionSymbol: new FormControl(),
      nvComment: new FormControl(),
      nvComment1: new FormControl(),
      nvRefundDescription: new FormControl(),
      nRefund: new FormControl(),
      nvNickname: new FormControl(),
      // iChildrenCount: new FormControl(),
      iFamilyLocation: new FormControl(),
      // nvCommunity: new FormControl()
    });
    this.formPopup = new FormGroup({
      nAmount: new FormControl("", Validators.required),
      iPaymentNum: new FormControl("", Validators.required),
      dDebitMonth: new FormControl("", Validators.required),
      nvComment: new FormControl("", Validators.required)
    });
    this.udChildDetails.iIdentityType = 3;
    this.udChildDetails.iStatusType = 26;
    this.udChildDetails.iBranchId = this.serv.lBranches[0].iId;
    this.GetCodeTablesByBranch();
    this.udChildDetails.iClassId = undefined;
    this.udChildDetails.iFamilyId = this.serv.currentiFamilyId;
    if (this.data.iChildId != -1) {
      this.GetChildDetails();
    } else {
      return this.serv
        .GetFamilyCodeTable()
        .then(res2 => {
          this.FamilyCodeTable = res2;
          // console.log("vgsdg", res2);
        })
        .catch(err => {
          console.log("err-----", err);
          return false;
        });
    }
  }

  onClickRadio(e) {
    if (e == 3) {
      //if it is ID
      // console.log("nvIdentity");
      this.form.controls["nvIdentity"].clearValidators();
      this.form.controls["nvIdentity"].setValidators([
        Validators.required,
        Validators.maxLength(9),
        Validators.minLength(9),
        this.serv.LegalTz
      ]);
      this.form.controls["nvIdentity"].updateValueAndValidity();
    } else {
      this.form.controls["nvIdentity"].clearValidators();
      this.form.controls["nvIdentity"].updateValueAndValidity();
    }
  }
  validateId(): void {
    const idToCheck = this.udChildDetails.nvIdentity ; // Get the ID value from the model
    this.isIdValid = this.idValidationService.validateIsraeliId(idToCheck);
  }
  public bankFilterCtrl: FormControl = new FormControl();
  GetChildDetails() {
    return this.serv
      .GetChildDetails(this.serv.user.iUserId, this.data.iChildId)
      .then(res => {
        return this.serv
          .GetFamilyCodeTable()
          .then(res2 => {
            this.FamilyCodeTable = res2;
            // console.log(res2);
            this.udChildDetails = res;
            this.validateId();
            // עקיפת באג:ממיר בחזרה מהסרבר(בכל הפרויקטים)בטווח התאריכים האלו יום פחות
            // if (this.udChildDetails.dBirthDate >= new Date(1996, 9, 16) && this.udChildDetails.dBirthDate <= new Date(1996, 9, 24)) {
            //   this.udChildDetails.dBirthDate.setDate(this.udChildDetails.dBirthDate.getDate() + 1);
            // }

            this.udChildDetails.identity.nvFilePath =
              AppProxy.baseUrl + "Files/" + this.udChildDetails.identity.nvFilePath;
            this.serv.iChildYearId = res.iChildYearId;
            // console.log("iChildYearId  " + res.iChildYearId);
            if (this.data.iChildId != -1) {
              this.isVisibleGrid = true;
              this.isVisibleAddDebitBtn = true;
              this.GetCodeTablesByBranch();
            }
          })
          .catch(err => {
            console.log("err-----", err);
            return false;
          });
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

  GetTuitionRateCodeTable() {
    return this.serv
      .GetTuitionRateCodeTable()
      .then(res => { })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

  addDebit() {
    this.debitAdittion = new DebitAddition();
    this.debitAdittion.iDebitId = -1;
    this.isVisible = true;
  }

  save() {
    if (this.form.valid == false) {
      this.alertResponse = "יש למלא את כל שדות החובה";
      this.filedsNotFull = true;
      return;
    }
    this.filedsNotFull = true;
    // לבקשת הלקוח להוריד אופציה
    if (this.udChildDetails.nTuitionAmount != null && (this.udChildDetails.nTuitionAmount).toString() == "")
      this.udChildDetails.nTuitionAmount = null;
    if (this.udChildDetails.nTransAmount != null && (this.udChildDetails.nTransAmount).toString() == "")
      this.udChildDetails.nTransAmount = null;
    this.udChildDetails.identity.nvFilePath = "";
    this.serv.saveUpdateChild(this.udChildDetails).then(res => {
      if (res) {
        if (res.nAmountSum < 0) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: ' נוספו זיכויים בסה"כ ' + res.nAmountSum * -1
          });
        } else if (res.nAmountSum > 0) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: ' נוספו חיובים בסה"כ ' + res.nAmountSum
          });
        }
        this.isVisibleAddDebitBtn = true;
        console.log(
          "UPDChildrenDetails  " + JSON.stringify(this.udChildDetails)
        );
        this.data.iChildId = res;
        this.udChildDetails.iChildId = res;
        this.dialogRef.close();
      }
    });
  }

  saveDebitAdittion() {
    if (this.formPopup.valid == false) {
      this.alertResponse = "יש למלא את כל שדות החובה";
      return;
    } else {
      this.lDebitAddition.push(this.debitAdittion);
      this.serv
        .InsUpdDebitAddition(this.lDebitAddition, this.serv.currentiFamilyId)
        .then(res => {
          if (res) {
          }
        });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onChangeClass(id) {
    console.log(id);
  }

  GetCodeTablesByBranch() {
    return this.serv
      .GetCodeTablesByBranch()
      .then(res => {
        this.isSelectedBranch = true;
        this.lClasses = [];
        //get classes by branch
        for (let i = 0; i < res.length; i++) {
          if (res[i].nvTableName == "Classes") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.udChildDetails.iBranchId
              ) {
                this.lClasses.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
          //סעה הלוך
          else if (res[i].nvTableName == "TransportRouteForth") {
            this.lTransportRouteForth = [];
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.udChildDetails.iBranchId
              ) {
                this.lTransportRouteForth.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
          //הסעה חזור
          else if (res[i].nvTableName == "TransportRouteBack") {
            this.lTransportRouteBack = [];
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.udChildDetails.iBranchId
              ) {
                this.lTransportRouteBack.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
          //הסעה שישי
          else if (res[i].nvTableName == "TransportRouteFriday") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.udChildDetails.iBranchId
              ) {
                this.lTransportRouteFriday.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
          //הסעה מיוחדת
          else if (res[i].nvTableName == "lTransportRouteSpecial") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.udChildDetails.iBranchId
              ) {
                this.lTransportRouteSpecial.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
        }
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

  onChangeBranch() {
    this.GetCodeTablesByBranch();
  }

  onChangeRate(event) {
    if (this.udChildDetails.iChildId != -1) {
      this.isVisible2 = true;
      this.isVisibleFields = false;

      if (event.source.id == "iMealsRateId") {
        //ארוחות
        this.rateSlct = "iMealsRateId";
      } else if (event.source.id == "iTranspRateId") {
        //הסעות
        this.rateSlct = "iTranspRateId";
      } else if (event.source.id == "iTuitionRateId") {
        //שכר לימוד
        this.rateSlct = "iTuitionRateId";
      }
    }
  }
  clicked(e) {
    if (e == 1) {
      if (this.toDate != null && this.toDate != null) {
        this.toDate = this.date;
        this.fromDate = this.date1;
      }
      this.isVisibleFields = true;
    } else if (e == 0) {
      this.isVisible2 = false;
    }
    else {
      if (this.rateSlct == "iMealsRateId") {
        this.udChildDetails.dMealsRateFromDate = this.fromDate;
        this.udChildDetails.dMealsRateToDate = this.toDate;
      } else if (this.rateSlct == "iTranspRateId") {
        this.udChildDetails.dTranspRateUpdatedFromDate = this.fromDate;
        this.udChildDetails.dTranspRateUpdatedToDate = this.toDate;
      } else if (this.rateSlct == "iTuitionRateId") {
        this.udChildDetails.dTuitionRateUpdatedFromDate = this.fromDate;
        this.udChildDetails.dTuitionRateUpdatedToDate = this.toDate;
      }
      this.isVisible2 = false;
    }
  }

  onBlur() {
    for (let i = 0; i < this.serv.lTuitionRate.length; i++) {
      if (
        this.serv.lTuitionRate[i].iRateId == this.udChildDetails.iTuitionRateId
      ) {
        if (
          this.udChildDetails.nTransAmount == null
        ){
          if(
          this.serv.lTuitionRate[i].nRate < this.udChildDetails.nDiscountAmount
          ) {
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "ההנחה יותר גדולה ממחיר שכר הלימוד "
            });
            this.udChildDetails.nDiscountAmount = null;
          }
        }
        else{
          if(
            this.udChildDetails.nDiscountAmount< this.udChildDetails.nDiscountAmount
          ){
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "ההנחה יותר גדולה ממחיר שכר הלימוד "
            });
          this.udChildDetails.nDiscountAmount = null;
          }
        }
      }
    }
  }

/*   selectLogo(event: any, id) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => { */
/*         this.udChildDetails.identity.nvFilePath = event.target.result;
        this.udChildDetails.identity.nvFileName = event.target.files[0].name; */
/*       };
      reader.readAsDataURL(event.target.files[0]);

      let x = this;
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        var fileType = file.name.substring(
          file.name.indexOf(".") + 1,
          file.name.length
        );

        if ((window as any).FileReader) {
          var fileBase64 = "";
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          var fileName;
          fileReader.onload = function (e) {
            fileBase64 = (e.target as any).result.substring(
              (e.target as any).result.indexOf(",") + 1,
              (e.target as any).result.length
            );
            x.nvImage = file.name;
            x.base64 = fileBase64;
          };
          this.udChildDetails.identity.nvFile = x.base64;
          this.udChildDetails.identity.nvFileName = x.nvImage;
          console.log(id, x, x.base64, x.nvImage);
        }
      }
     // this.udChildDetails.identity.nvFile = x.base64;
     // this.udChildDetails.identity.nvFileName = x.nvImage;
    }
  } */

  selectLogo(event: any, id) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event1: any) => {
        // if (id == 1) this.pDetails.identityF.nvFilePath = event1.target.result;
        // else this.pDetails.identityM.nvFilePath = event1.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);

      let x = this.fileChange(event, id);
      console.log(id, x, x.base64, x.nvImage);
      // if (id == 2) {
      //   console.log(2, x, x.base64, x.nvImage, this.pDetails);
      //   this.pDetails.identityF.nvFile = x.base64;
      //   this.pDetails.identityF.nvFileName = x.nvImage;
      // } else {
      //   console.log(1, x, x.base64, x.nvImage, this.pDetails);
      //   this.pDetails.identityM.nvFile = x.base64;
      //   this.pDetails.identityM.nvFileName = x.nvImage;
      // }
      // console.log(this.pDetails);
    }
  }

  fileChange(event, id) {
    let x = this;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var fileType = file.name.substring(
        file.name.indexOf(".") + 1,
        file.name.length
      );
      if ((window as any).FileReader) {
        var fileBase64 = "";
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        var fileName;
        fileReader.onload = function (e) {
          fileBase64 = (e.target as any).result.substring(
            (e.target as any).result.indexOf(",") + 1,
            (e.target as any).result.length
          );
          console.log(fileBase64);
          x.nvImage = file.name;
          x.base64 = fileBase64;

          console.log(2, x, x.base64, x.nvImage,x.udChildDetails);
          x.udChildDetails.identity.nvFile = x.base64;
          x.udChildDetails.identity.nvFileName = x.nvImage;
        };
      }
    }
    return x;
  }
  show(path) {
    let a = this.winRef.getNativeWindow();
    a.open(/* AppProxy.baseUrl + "Files/" + */ path);
  }

  bringHebrewDate() {
    // console.log("{change")
    // this.hebrewDateService.

    var hDate = this.hebrewDateService.HebrewDateFilter(
      this.udChildDetails.dBirthDate,
      "hebrewDay"
    );
    var monthDate = this.hebrewDateService.HebrewDateFilter(
      this.udChildDetails.dBirthDate,
      "hebrewMonth"
    );
    var myDate = this.hebrewDateService.HebrewDateFilter(
      this.udChildDetails.dBirthDate,
      "hebrew"
    );
    this.udChildDetails.nvJewBirthDate = myDate;
    // this.udChildDetails.dBirthDate
    // this.udChildDetails.nvJewBirthDate
  }

  onDateInput(event: any, controlName: string) {
    // Clear the previous timer
    clearTimeout(this.typingTimer);

    // Set a new timer (debounce)
    this.typingTimer = setTimeout(() => {
      const inputDate = event.target.value;
      const parsedDate = this.parseDate(inputDate);

      // If the date is valid, update the form control
      const control = this.form.get(controlName);
      if (control) {
        control.setValue(parsedDate);
      }
    }, 1000); // 1000ms delay
  }

  parseDate(dateString: string): Date | null {
    if (!dateString) return null;

  // Split by various possible delimiters: '/', '-', ' ', '.'
    const parts = dateString.split(/[/\-\s.]+/);
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // months are zero-indexed
      const year = parseInt(parts[2], 10);

      // Check if the parsed date is valid
      if (this.isValidDate(day, month, year)) {
        return new Date(year, month, day);
      }
    }
    return null;
  }

  isValidDate(day: number, month: number, year: number): boolean {
    // Check if the date is valid by creating a Date object
    const date = new Date(year, month, day);
    return (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    );
  }
/*   fileChange(event) {
    let x = this;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var fileType = file.name.substring(
        file.name.indexOf(".") + 1,
        file.name.length
      );
      if ((window as any).FileReader) {
        var fileBase64 = "";
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        var fileName;
        fileReader.onload = function (e) {
          fileBase64 = (e.target as any).result.substring(
            (e.target as any).result.indexOf(",") + 1,
            (e.target as any).result.length
          );
          x.nvImage = file.name;
          x.base64 = fileBase64;
        };
      }
    }
    return x;
  } */
}
