import { User } from './../../../Models/User';
import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UpdateChildrenComponent } from "../../../Components/update-children/update-children.component";
import { ServService } from "../../serv.service";
import { DebitAddition } from "../../../Models/UpdateChildrenDetails";
import * as moment from "moment";
import { LOCALE_TEXT } from "../constants";
import { GridApi } from "ag-grid";
import { CodeTable } from "src/Models/CodeTable";
import { Observable } from "rxjs";

@Component({
  selector: "app-form-children-details",
  templateUrl: "./form-children-details.component.html",
  styleUrls: ["./form-children-details.component.scss"]
})
export class FormChildrenDetailsComponent implements OnInit {
  
  backgroundColor: string;
  form;
  typingTimer: any;
  @Input() cDetails;
  @Output() close = new EventEmitter();
  isDisable: boolean = true;
  isVisible: boolean = false;
  debitAdittion: DebitAddition = new DebitAddition();
  lDebitAddition: Array<DebitAddition>;
  formPopup: FormGroup;
  childId: number = 0;
  numChildren: number = 0;
  sumTotal: number = 0;
  alertResponse = "";
  displayAlert = false;
  isVisible2 = false;
  gridApi: GridApi;
  dialogRef: MatDialogRef<UpdateChildrenComponent>;
  checkTempArr: Array<Date>;
  date: Date = new Date();
  isVisibleDeletePopup: boolean = false;
  debitToDelete = null;
  public column;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public columnTypes;
  public myForm: FormGroup;
  sumChecks = null;
  isDebitSequence = false;
  isVisibleTransfer = false;
  public iChildId: number;
  families: CodeTable[];
  newFamily = null;
  public nTuitionAmount: string;
  public nRefund: string;
  public nDiscountAmount: string;
  dFromDate: Date = undefined;
  
  get controls() {
    return (<FormArray>this.myForm.controls.addresses).controls;
  }
  udChildDetails: UpdateChildrenComponent;
  constructor(
    private route: Router,
    private updateChildDailog: MatDialog,
    public service: ServService,
    private _fb: FormBuilder
  ) {
    this.backgroundColor = service.backgroundColor[0];
    this.lDebitAddition = new Array<DebitAddition>();
    this.debitAdittion.dDebitMonth = new Date();
    this.checkTempArr = new Array<Date>();
    // if (this.service.childDetails != undefined && this.service.childDetails != null) {
    //   this.showDebitAdittion(this.service.childDetails.iChildId)
    //   this.service.childDetails = null;
    // }
  }

  ngOnInit() {
    this.dFromDate = this.service.default1.dCreditFamilyFromDate;

    this.myForm = this._fb.group({
      name: ["", [Validators.required, Validators.minLength(5)]],
      addresses: this._fb.array([
      ])
    });
    this.debitAdittion.iPaymentNum = 1;
    this.form = new FormGroup({
      nvFirstName: new FormControl(),
      nTransAmount: new FormControl(),
      nvLevel: new FormControl(),
      nvBranchName: new FormControl(),
      nMealsAmount: new FormControl(),
      nTuitionAmount: new FormControl(),
      nvStatusName: new FormControl(),
      nAmount: new FormControl(),
      nDiscountAmount: new FormControl(),
      nTotal: new FormControl(),
      nvRefundDescription: new FormControl(),
      nRefund: new FormControl()
    });
    this.formPopup = new FormGroup({
      nAmount: new FormControl("", Validators.required),
      iPaymentNum: new FormControl("", Validators.required),
      dDebitMonth: new FormControl("", Validators.required),
      nvComment: new FormControl("", Validators.required),
      sumChecks: new FormControl(),
      iStatusType: new FormControl("", Validators.required),
      iAssociationId: new FormControl("", Validators.required),
      iRateType: new FormControl(),
      iRateId: new FormControl()
    });
    this.gridOptions = {
      localeText: { LOCALE_TEXT },
      context: { componentParent: this },
      enableRtl: true,
      onGridReady: this.onGridReady,
      defaultColDef: {
        width: 50,
        resizable: true
      }
    };
    this.column = [
      {
        headerName: "מחיקה",
        width: 80,
        suppressFilter: true,
        cellRenderer: function (params) {
          if (params.data.iStatusType != 12 /* && this.service.user.bReadOnly != true */) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button class="btn-icon-grid" id="edit"><span class="icon-garbage"></span></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.isVisibleDeletePopup = true;
              params.context.componentParent.debitToDelete = params.data;
            });
            return eDiv;
          }
        }
      },
      {
        headerName: "עריכה",
        width: 80,
        suppressFilter: true,
        cellRenderer: function (params) {
          if (params.data.iStatusType != 12 /* && this.service.user.bReadOnly != true */) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.editDebit(params.data);
            });
            return eDiv;
          }
        }
      },
      {
        headerName: "סכום לכל תשלום",
        field: "nAmount",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "חיוב מספר",
        field: "iPaymentNum",
        width: 100,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך חיוב",
        field: "dDebitDate",
        width: 155,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "עבור חודש",
        field: "dDebitMonth",
        width: 155,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "סוג תעריף",
        field: "nvRateType",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף",
        field: "nvRateName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatusType",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסבר",
        field: "nvComment",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת. עדכון",
        field: "dtLastModifyDate",
        width: 155,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      }
    ];
    this.nTuitionAmount = "₪" + this.cDetails.nTuitionAmount;
    this.nRefund = "₪" + this.cDetails.nRefund;
    this.nDiscountAmount = "₪" + this.cDetails.nDiscountAmount;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  initAddress() {
    return this._fb.group({
      nAmount: ["", Validators.required],
      iPaymentNum: ["", Validators.required],
      dDebitMonth: ["", Validators.required],
      nvComment: ["", Validators.required],
      iStatusType: ["", Validators.required],
      iAssociationId: [""]
    });
  }
  dateComparator(date1, date2) {
    if (
      (date1 === null && date2 === null) ||
      (typeof date1 == "undefined" && typeof date2 == "undefined")
    ) {
      return 0;
    }
    if (date1 === null || typeof date1 == "undefined") {
      return -1;
    }
    if (date2 === null || typeof date2 == "undefined") {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }
  addAddress() {
    for (let i = 0; i < this.sumChecks - 1; i++) {
      this.lDebitAddition.push(new DebitAddition());
      this.lDebitAddition[i].iDebitId = -1;
      this.lDebitAddition[i].nAmount = this.debitAdittion.nAmount;
      this.lDebitAddition[i].nvComment = this.debitAdittion.nvComment;
      this.lDebitAddition[i].iStatusType = this.debitAdittion.iStatusType;
      this.lDebitAddition[i].iPaymentNum =
      this.debitAdittion.iPaymentNum + i + 1;
      this.lDebitAddition[i].dDebitMonth = this.checkTempArr[i];
      this.lDebitAddition[i].iAssociationId = this.debitAdittion.iAssociationId;
      this.lDebitAddition[i].iRateType = this.debitAdittion.iRateType;
      this.lDebitAddition[i].iRateId = this.debitAdittion.iRateId;      
      const control = <FormArray>this.myForm.controls["addresses"];
      control.push(this.initAddress());
    }
  }

  updateDetails(iChildId) {
    this.numChildren += 1;
    this.dialogRef = this.updateChildDailog.open(UpdateChildrenComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px",
      data: { iChildId: iChildId }
    });
    this.dialogRef
      .afterClosed()
      .toPromise()
      .then(res => {
        this.close.emit();
      });
  }

  GetChildDetails() {
    return this.service
      .GetChildDetails(this.service.user.iUserId, this.childId)
      .then(res => {
        this.service.iChildYearId = res.iChildYearId;
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

  updateChildDetails() {
    this.updateChildDailog.open(UpdateChildrenComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px"
    });
  }

  addDebit(iChildId) {
    this.childId = iChildId;
    this.debitAdittion = new DebitAddition();
    this.debitAdittion.iDebitId = -1;
    this.debitAdittion.dDebitMonth = new Date();
    const control = <FormArray>this.myForm.controls["addresses"];
    let l = control.length;
    for (let i = 0; i < l; i++) {
      control.removeAt(0);
    }
    control.removeAt(0);
    this.isVisible2 = false;
    this.isVisible = true;
  }

  saveDebitAdittion() {
    if (!this.debitAdittion.bDel && this.formPopup.valid == false) {
      this.alertResponse = "יש למלא את כל שדות החובה";
      this.displayAlert = true;
      return;
    } else {
      this.service.iChildYearId = this.cDetails.iChildYearId
      //this.GetChildDetails();
      setTimeout(() => {
        if (!this.isDebitSequence) {
          this.lDebitAddition.splice(0, this.lDebitAddition.length);
        }
        let l = new Array<DebitAddition>();
        l.push(this.debitAdittion);
        for (let i = 0; i < this.lDebitAddition.length; i++) {
          l.push(this.lDebitAddition[i]);
        }
        if (!(typeof this.debitAdittion.dDebitMonth == 'undefined')) {
          if (!(typeof this.debitAdittion.dDebitMonth.getMonth === 'function')) {
            let dateParts = this.debitAdittion.dDebitMonth.split("/");
            let cellDate = new Date(
              Number(dateParts[1]) +
              "/" +
              Number(dateParts[0]) +
              "/" +
              Number(dateParts[2])
            );
            this.debitAdittion.dDebitMonth = cellDate;
          }
        }
        if (!(typeof this.debitAdittion.dDebitDate == 'undefined')) {
          if (!(typeof this.debitAdittion.dDebitDate.getMonth === 'function')) {
            let dateParts1 = this.debitAdittion.dDebitDate.split("/");
            let cellDate1 = new Date(
              Number(dateParts1[1]) +
              "/" +
              Number(dateParts1[0]) +
              "/" +
              Number(dateParts1[2])
            );
            this.debitAdittion.dDebitDate = cellDate1;
          }
        }
        this.debitAdittion.dtLastModifyDate = new Date()
        this.service
          .InsUpdDebitAddition(l, this.service.currentiFamilyId)
          .then(res => {
            if (res) {
              if (l[l.length - 1].iDebitId == -1)
                l[l.length - 1].iDebitId = res;
              if (!this.debitAdittion.bDel) this.isVisible = false;
              this.close.emit("");

            }
          });
      }, 100);
    }
  }

  GetDebitAddition() {
    return this.service
      .GetChildDetails(this.service.user.iUserId, this.childId)
      .then(res => {
        this.service.iChildYearId = res.iChildYearId;
        return this.service
          .GetDebitAddition(this.service.user.iUserId,this.dFromDate)
          .then(res => {
            for (let i = 0; i < res.length; i++) {
              if (res[i].dDebitMonth != null)
                res[i].dDebitMonth = moment(res[i].dDebitMonth).format(
                  "DD/MM/YYYY"
                );
              else {
                res[i].dDebitMonth = "";
              }

              if (res[i].dDebitDate != null)
                res[i].dDebitDate = moment(res[i].dDebitDate).format(
                  "DD/MM/YYYY"
                );
              else {
                res[i].dDebitDate = "";
              }

              if (res[i].dtLastModifyDate != null)
              res[i].dtLastModifyDate = moment(res[i].dtLastModifyDate).format(
                "DD/MM/YYYY"
              );
            else {
              res[i].dtLastModifyDate = "";
            }
            }
            this.lDebitAddition = res;
            this.rowData = res;
          })
          .catch(err => {
            console.log("err-----", err);
            return false;
          });
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

  showDebitAdittion(childId) {
     this.childId = childId;
    return this.service
      .GetChildDetails(this.service.user.iUserId, childId)
      .then(res => {
        this.service.iChildYearId = res.iChildYearId;
        this.service.iChildId = res.iChildId;
        return this.service
          .GetDebitAddition(this.service.user.iUserId,this.dFromDate)
          .then(res => {
            for (let i = 0; i < res.length; i++) {
              if (res[i].dDebitMonth != null)
                res[i].dDebitMonth = moment(res[i].dDebitMonth).format(
                  "DD/MM/YYYY"
                );
              else {
                res[i].dDebitMonth = "";
              }

              if (res[i].dDebitDate != null)
                res[i].dDebitDate = moment(res[i].dDebitDate).format(
                  "DD/MM/YYYY"
                );
              else {
                res[i].dDebitDate = "";
              }
              
              if (res[i].dtLastModifyDate != null)
              res[i].dtLastModifyDate = moment(res[i].dtLastModifyDate).format(
                "DD/MM/YYYY"
              );
            else {
              res[i].dtLastModifyDate = "";
            }              
            }
            this.lDebitAddition = res;
            this.rowData = res;
            this.isVisible2 = true;
          })
          .catch(err => {
            console.log("err-----", err);
            return false;
          });
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }


  editDebit(params) {
    this.isVisible = true;
    this.debitAdittion = params;
    if (!(typeof this.debitAdittion.dDebitMonth == 'undefined')) {
      if (!(typeof this.debitAdittion.dDebitMonth.getMonth === 'function')) {
        let dateParts = this.debitAdittion.dDebitMonth.split("/");
        let cellDate = new Date(
          Number(dateParts[1]) +
          "/" +
          Number(dateParts[0]) +
          "/" +
          Number(dateParts[2])
        );
        this.debitAdittion.dDebitMonth = cellDate;
      }
    }
  }

  deleteDebit(params, childDetails) {
    this.debitAdittion = params;
    this.iChildId = this.childId
    this.debitAdittion.bDel = true;
    this.saveDebitAdittion();
    this.GetDebitAddition();
    setTimeout(function () {
      this.showDebitAdittion(this.iChildId);
    }, 1000);

    // this.service.childDetails = childDetails;
  }

  showDebitSequence() {
    this.isDebitSequence = true;
  }

  showListDebit() {
    const control = <FormArray>this.myForm.controls["addresses"];
    let l = control.length;
    for (let i = 0; i < l; i++) {
      control.removeAt(0);
    }
    control.removeAt(0);
    this.lDebitAddition = new Array<DebitAddition>();
    this.date = new Date(this.debitAdittion.dDebitMonth);
    for (let i = 0; i < this.sumChecks; i++) {
      this.checkTempArr.push(
        new Date(this.date.setMonth(this.date.getMonth() + 1))
      );
    }
    this.addAddress();
  }
  transfer() {
    this.service.GetFamilyCodeTable().then(res => { this.families = res; this.isVisibleTransfer = true })

  }
  familyTransfer() {
    this.service.UpdChildFamily(this.newFamily, this.cDetails.iChildId).then(res => {
      if (res) {
        this.service.lcDetails.splice(this.service.lcDetails.indexOf(this.service.lcDetails.filter(c => c.iChildId = this.cDetails.iChildId)[0]), 1);
      }
    })
  }
  onDateInput(event: any, controlName: string) {
    // Clear the previous timer
    clearTimeout(this.typingTimer);

    // Set a new timer (debounce)
    this.typingTimer = setTimeout(() => {
      const inputDate = event.target.value;
      const parsedDate = this.parseDate(inputDate);

      // If the date is valid, update the form control
      const control = this.form.get(controlName);
      if (control) {
        control.setValue(parsedDate);
      }
    }, 1000); // 1000ms delay
  }

  parseDate(dateString: string): Date | null {
    if (!dateString) return null;

  // Split by various possible delimiters: '/', '-', ' ', '.'
    const parts = dateString.split(/[/\-\s.]+/);
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // months are zero-indexed
      const year = parseInt(parts[2], 10);

      // Check if the parsed date is valid
      if (this.isValidDate(day, month, year)) {
        return new Date(year, month, day);
      }
    }
    return null;
  }

  isValidDate(day: number, month: number, year: number): boolean {
    // Check if the date is valid by creating a Date object
    const date = new Date(year, month, day);
    return (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    );
  }
}
