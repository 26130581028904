export class DebitAdditionByClass {
    iBranchId: number
    iFromClassId: number
    iToClassId: number
    nAmount: number
    iDebitCount: number
    dDebitMonth: Date=new Date()
    nvComment: string
    iPaymentNum:number
    iStatusType:number
    iAssociationId:number
    bInsertPayments:boolean
}