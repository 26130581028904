export class Branch{
   iBranchId:number=-1
   nvBranchName:string
   nvAddress:string
   bIsAugustCalRelative :boolean
   bIsHalfMonth :boolean
   bIsChildInLastClassBecomesGraduate:boolean
   bIsGoUpClasses:boolean
   iDayHalfMonth:number
   bIsNewMoon:boolean
   bIsRate2:boolean
   iHighestClassId:number

}