import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServService } from '../../app/serv.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AppProxy } from '../../app/app.proxy';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isWait: boolean;
  template: string = `<img src="../../assets/icons/2.gif"  class="loader"/>`;
  backgroundColor: string[];
  router: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  nvFirstName: string
  nvLastName: string
  constructor(private route: Router, public service: ServService, private idle: Idle, private keepalive: Keepalive) {
    this.backgroundColor = this.service.backgroundColor
    this.nvFirstName = this.service.user.nvFirstName;
    this.nvLastName = this.service.user.nvLastName;
    this.isWait = AppProxy.isWait
  }

  ngOnInit() {
    this.service.visibleStatus = true
  }

  ngOnDestroy() {
    this.service.visibleStatus = false
  }

  logOut() {
    this.route.navigate(['/login']);
  }

  reset() {
    this.idle.watch();
    this.idleState = '';
    this.timedOut = false;

  }
}
