import { Data } from '@angular/router';
import { Component, OnInit, ViewChild } from "@angular/core";
import { LOCALE_TEXT } from "../../app/Additions/constants";
import { ServService } from "../../app/serv.service";
import * as moment from "moment";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl
} from "@angular/forms";
import { FamilyCredit } from "../../Models/FamilyCredit";
import { CodeTable } from "../../Models/CodeTable";
import { Month } from "../../Models/Month";
import { MatDialog } from "@angular/material/dialog";
import { dailog } from "../../app/Additions/dailog/dailog";
import { User } from "src/Models/User";
import { unwatchFile } from "fs";
@Component({
  selector: "app-family-credit",
  templateUrl: "./family-credit.component.html",
  styleUrls: ["./family-credit.component.css"]
})
export class FamilyCreditComponent implements OnInit {
  manualCreditTitle: string;
  form1: FormGroup;
  typingTimer: any;
  titleForSequence = "רצף תשלומים"
  public column;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;
  dFromDate: Date = undefined;
  date: Date = new Date();
  dateTemp: Date = new Date();
  isVisibleManualCreditPopup = false;
  IsVisibleEditReturnPopup = false;
  cash = "cash";
  check = "check";
  directDebit = "directDebit";
  creditCard = "creditCard";
  checkTempArr: Array<Date>;
  isVisibleCheck = false;
  isVisibleDirectDebit = false;
  isVisibleCreditCard = false;
  form: FormGroup;
  backgroundColor: string;
  manualCredit: FamilyCredit;
  lManualCredit: Array<FamilyCredit>;
  lCreditCard: Array<CodeTable>;
  lDirectDebit: Array<CodeTable>;
  isVisibleFieldsCreditCard = false;
  isVisibleCheckPopup = false;
  lSequenceCredit: Array<FamilyCredit>;
  sequenceCredit: FamilyCredit;
  credit: any;
  index: number = 0;
  isEdit = false;
  sumCredit = null;
  creditDateIndex;
  monthes: Array<Month>;
  isSequenceCredit = false;
  // monthes: string[];
  public myForm: FormGroup;
  @ViewChild("f", { static: true }) myNgForm;
  date2: Date = new Date();
  date3: Date = new Date();
  dDebitDate: Date;
  paymentTodelete = null;
  paymentToToggle = null;
  isVisibleDeletePopup: boolean = false;
  isChecks: boolean = false;
  isSequence: number = 0;
  get controls() {
    return (<FormArray>this.myForm.controls.addresses).controls;
  }

  constructor(public serv: ServService, private _fb: FormBuilder, private dailog: MatDialog) {
    this.backgroundColor = this.serv.backgroundColor[0];
    this.manualCredit = new FamilyCredit();
    this.lManualCredit = new Array<FamilyCredit>();
    this.lCreditCard = new Array<CodeTable>();
    this.lDirectDebit = new Array<CodeTable>();
    this.lSequenceCredit = new Array<FamilyCredit>();
    this.sequenceCredit = new FamilyCredit();
    // this.sequenceChecks.dCheckDate = new Date();
    this.checkTempArr = new Array<Date>();
    this.monthes = new Array<Month>();
  }

  ngOnInit() {
    this.GetMonthesCodeTable();

    this.form = new FormGroup({
      iCreditMethodType: new FormControl("", Validators.required),
      dCreditDate: new FormControl(),
      dTransactDate: new FormControl(),
      // {value:'',disabled: this.isChecks}
      nAmount: new FormControl(),
      iPaymentMethodId: new FormControl(),
      // iPaymentMethodId: new FormControl("", Validators.required),
      iAssociationId: new FormControl(),
      dExpirationDate: new FormControl(),
      nvCardNumber: new FormControl(),
      CVV: new FormControl(),
      iCheckNum: new FormControl(),
      dCheckDate: new FormControl(),
      iStatusType: new FormControl(),
      nvComment: new FormControl(),
      sumCredit: new FormControl(),
      bImmediateCharge: new FormControl(),
      bToReceipte: new FormControl(),
      bBlankCheck: new FormControl(),
      iDocumentNumber: new FormControl(),
      
      iBankType: new FormControl(),
      iBranchNumber: new FormControl(),
      iaccountNumber: new FormControl(),

    });

    this.form1 = new FormGroup({
      iCreditMethodType: new FormControl("", Validators.required),
      dCreditDate: new FormControl(),
      dTransactDate: new FormControl(),
      nAmount: new FormControl(),
      iPaymentMethodId: new FormControl("", Validators.required),
      iAssociationId: new FormControl("", Validators.required),
      dExpirationDate: new FormControl(),
      nvCardNumber: new FormControl(),
      CVV: new FormControl(),
      iCheckNum: new FormControl(),
      dCheckDate: new FormControl(),
      iStatusType: new FormControl(),
      nvComment: new FormControl(),
      nvConfirmationNum: new FormControl(),
      bImmediateCharge: new FormControl(),
      bToReceipte: new FormControl(),
      bBlankCheck: new FormControl(),
      iDocumentNumber: new FormControl(),
      bPayInDolar :new FormControl(),
      nDolarAmount :new FormControl(),
      iBankType: new FormControl(),
      iBranchNumber: new FormControl(),
      iaccountNumber: new FormControl(),

    });

    this.myForm = this._fb.group({
      name: ["", [Validators.required, Validators.minLength(5)]],
      addresses: this._fb.array([
        // this.initAddress(),
      ])
    });

    this.dFromDate = this.serv.default1.dCreditFamilyFromDate;

    this.getCredit();
    this.GetLastDebitDateFamily();
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };

    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      defaultColDef: {
        resizable: true
    },
      onGridReady: this.onGridReady
    };

    this.column = [
      {
        headerName: "מחיקה",
        width: 130,
        suppressFilter: true,

        cellRenderer: function (params) {
          if (
            params.data.iStatusType == 32 && params.data.bReadOnly != true||
            params.data.iCreditMethodType == 49  && params.data.iDocumentNumber == null && params.data.bReadOnly != true||
            params.data.iCreditMethodType == 76 && params.data.bReadOnly != true
          ) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="button" class="btn-icon-grid" id="edit"><i class="icon-garbage"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.isVisibleDeletePopup = true;
              params.context.componentParent.paymentTodelete = params.data;
              // params.context.componentParent.deletePayment(params.data);
            });
            return eDiv;
          }
        }
      },
      {
        headerName: "עריכה",
        width: 120,
        suppressFilter: true,

        cellRenderer: function (params) {
          if (
            params.data.iStatusType == 32 && params.data.bReadOnly != true ||
            params.data.iCreditMethodType == 49 && params.data.iDocumentNumber == null && params.data.bReadOnly != true||
            params.data.iCreditMethodType == 76 && params.data.bReadOnly != true ||
            params.data.iStatusType == 34 && params.data.bReadOnly != true ||
            params.data.iStatusType == 35 && params.data.bReadOnly != true ||
            params.data.iStatusType == 1091 && params.data.bReadOnly != true 
          ) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="button" class="btn-icon-grid" id="edit"><i class="icon-edit"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.editPayment(params.data);
              params.context.componentParent.paymentToToggle = params.data;
            });
            return eDiv;
          }
        }
      },
      {
        headerName: "הדפסת קבלה",
        width: 150,
        suppressFilter: true,

        cellRenderer: function (params) {
          if (params.data.iStatusType == 33 && params.data.nvPaymentMethodType != "הסדר חוב" && params.data.bReadOnly != true) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="button" class="btn-icon-grid" id="edit"><i class="icon-floppy-disk"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.printPDF(params.data);
            });
            return eDiv;
          }
        }
      },
      {
        headerName: "עבור חודש",
        field: "dCreditDate2",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      }, {
        headerName: "תאריך צ'ק",
        field: "dCheckDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "תאריך ביצוע",
        field: "dTransactDate2",
        width: 200,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },

      {
        headerName: "א. תשלום",
        field: "nvPaymentMethodType",
        width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג תשלום",
        field: "nvCreditType",
        width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס' חשבון/כרטיס",
        field: "nvPaymentMethodDetails",
        width: 200,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום",
        field: "nAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ג. בדולר",
        field: "nDolarAmount",
        width: 130,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatusType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },     
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס' אישור",
        field: "nvConfirmationNum",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComment",
        width: 220,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עבור קבלה",
        field: "nvToReceipte",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];

    if (this.serv.user.iReceiptCompany == 1078 || this.serv.user.iReceiptCompany == 1079)
      this.column.push({
        headerName: "מס' קבלה",
        field: "iDocumentNumber",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      })
  }

  GetLastDebitDateFamily() {
    this.serv.GetLastDebitDateFamily().then(res => {
      if (res) {
        this.dDebitDate = res;
      }
    });
  }

  dateComparator(date1, date2) {
    if (
      (date1 === null && date2 === null) ||
      (typeof date1 == "undefined" && typeof date2 == "undefined")
    ) {
      return 0;
    }
    if (date1 === null || typeof date1 == "undefined") {
      return -1;
    }
    if (date2 === null || typeof date2 == "undefined") {
      return 1;
    }
    // if(date1&&date2){
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    if (cellDate1 === null && cellDate2 === null) {
      return 0;
    }
    if (cellDate1 === null) {
      return -1;
    }
    if (cellDate2 === null) {
      return 1;
    }
    return cellDate1.getTime() - cellDate2.getTime();

    // }
  }

  ImmediateChargeChanged(event) {
    if (event.checked) {
      this.manualCredit.dTransactDate = new Date();
    } else {
      console.log(":)", event.checked);
    }
  }

  ToReceipte(event) {
    if (event.checked) {
      this.manualCredit.bToReceipte = true;
    } else {
      this.manualCredit.bToReceipte = false;
    }
  }

  PayInDolar(event) {
    if (event.checked) {
      this.manualCredit.bPayInDolar = true;
    } else {
      this.manualCredit.bPayInDolar = false;
    }
  }

  initAddress() {
    return this._fb.group({
      iCreditMethodType: ["", Validators.required],
      dCreditDate: [],
      dTransactDate: [],
      bBlankCheck: [],
      nAmount: [],
      iPaymentMethodId: [],
      iAssociationId: [],
      dExpirationDate: [],
      nvCardNumber: [],
      CVV: [],
      iCheckNum: [],
      dCheckDate: [],
      iStatusType: [],
      nvComment: [],

      iBankType: [],
      iBranchNumber: [],
      iaccountNumber: [],

      // iStatusType: ['', Validators.required],
    });
  }

  onChange(i) {
    if (i != null) {
      if (this.lSequenceCredit[i].bBlankCheck) {
        this.lSequenceCredit[i].iStatusType = 32;
        this.myForm.controls["addresses"]["controls"][i].controls['nAmount'].disable();
      } else {
        this.myForm.controls["addresses"]["controls"][i].controls['nAmount'].enable();
      }

    } else {
      if (this.manualCredit.bBlankCheck) {
        this.manualCredit.iStatusType = 32;
      }

    }
  }

  addAddresses: boolean = false;
  addAddress() {
    this.addAddresses = true;
    this.creditDateIndex = this.manualCredit.dCreditDate;
    let index = 0;
    for (let j = 0; j < this.monthes.length; j++) {
      if (this.monthes[j].dtMonth == this.creditDateIndex) {
        index = j;
      }
    }



    for (let i = 0; i < this.sumCredit - 1; i++) {
      // this.lSequenceCredit[i].iPaymentMethodId = this.manualCredit.iPaymentMethodId;
      this.lSequenceCredit.push(new FamilyCredit());
      // if (this.manualCredit.iCreditMethodType == 38) {
        this.date = new Date(this.manualCredit.dCheckDate);
        this.date2 = new Date(this.manualCredit.dCreditDate);
        this.date3 = new Date(this.manualCredit.dTransactDate);         
      if (this.isChecks) {
       if (this.date.getMonth() + i +1 == 1 /* פברואר */ && this.date.getDate() > 28 ){
          if(this.date.getFullYear() %4 == 0 && this.date.getFullYear() % 100 != 0 || this.date.getFullYear() %4 == 0 && this.date.getFullYear() % 400 == 0 ){
            this.date.setDate(29);
          } else{
            this.date.setDate(28);
          }
        }
        this.lSequenceCredit[i].iCheckNum = this.manualCredit.iCheckNum + i + 1;
        this.lSequenceCredit[i].dCheckDate = new Date(
          this.date.setMonth(this.date.getMonth() + i + 1)
        ); 
      } //else {
        if (new Date(this.manualCredit.dCreditDate) > this.dDebitDate) {
          if (this.date2.getMonth() + i +1 == 1 /* פברואר */ && this.date2.getDate() > 28 ){
            if(this.date2.getFullYear() %4 == 0 && this.date2.getFullYear() % 100 != 0 || this.date2.getFullYear() %4 == 0 && this.date2.getFullYear() % 400 == 0 ){
              this.date2.setDate(29);
            } else{
              this.date2.setDate(28);
            }
          }
          this.lSequenceCredit[i].dCreditDate = new Date(
            this.date2.setMonth(this.date2.getMonth() + i + 1)
          );

/*           this.lSequenceCredit[i].dCreditDate = new Date(
            this.date2.setMonth(this.date2.getMonth() + 1)
          ); */
        } else {
            this.lSequenceCredit[i].dCreditDate = new Date(
              this.manualCredit.dCreditDate
            );          
        }

        if (this.date3.getMonth() + i +1 == 1 /* פברואר */ && this.date3.getDate() > 28 ){
          if(this.date3.getFullYear() %4 == 0 && this.date3.getFullYear() % 100 != 0 || this.date3.getFullYear() %4 == 0 && this.date3.getFullYear() % 400 == 0 ){
            this.date3.setDate(29);
          } else{
            this.date3.setDate(28);
          }
        }
        this.lSequenceCredit[i].dTransactDate = new Date(
          this.date3.setMonth(this.date3.getMonth() + i + 1)
        );

        this.lSequenceCredit[
          i
        ].iCreditMethodType = this.manualCredit.iCreditMethodType;
        this.lSequenceCredit[i].iStatusType = this.manualCredit.iStatusType;
        this.lSequenceCredit[i].bBlankCheck = this.manualCredit.bBlankCheck;


        this.lSequenceCredit[i].iCreditMethodType = this.manualCredit.iCreditMethodType;
     // }

      if (this.manualCredit.iCreditMethodType == 13) {
        this.lSequenceCredit[i].iCVV = this.manualCredit.iCVV;
        this.lSequenceCredit[
          i
        ].nvExpirationDate = this.manualCredit.nvExpirationDate;
        this.lSequenceCredit[
          i
        ].iCreditMethodType = this.manualCredit.iCreditMethodType;
        // this.lSequenceCredit[i].nvCardLastFourDigits = this.manualCredit.nvCardLastFourDigits;
        this.lSequenceCredit[i].nvCardNumber = this.manualCredit.nvCardNumber;
        this.lSequenceCredit[
          i
        ].iAssociationId = this.manualCredit.iAssociationId;
        this.lSequenceCredit[
          i
        ].iPaymentMethodId = this.manualCredit.iPaymentMethodId;
        this.lSequenceCredit[i].iStatusType = this.manualCredit.iStatusType;
        this.lSequenceCredit[i].bPayInDolar = this.manualCredit.bPayInDolar;
        this.lSequenceCredit[i].nDolarAmount = this.manualCredit.nDolarAmount;
      }
      if (this.manualCredit.iCreditMethodType == 14) {
        this.lSequenceCredit[
          i
        ].iPaymentMethodId = this.manualCredit.iPaymentMethodId;
        this.lSequenceCredit[
          i
        ].iCreditMethodType = this.manualCredit.iCreditMethodType;
        this.lSequenceCredit[i].iStatusType = this.manualCredit.iStatusType;
      }
      this.lSequenceCredit[i].iStatusType = this.manualCredit.iStatusType;
      
/*      העברתי למעלה יחד עם תאריך ציק תוך פתרון עבור פברואר

   if (new Date(this.manualCredit.dCreditDate) > this.dDebitDate) {
        this.lSequenceCredit[i].dCreditDate = new Date(
          this.date2.setMonth(this.date2.getMonth() + 1)
        );
      } else
        this.lSequenceCredit[i].dCreditDate = new Date(
          this.manualCredit.dCreditDate
        );
      var date33 = new Date(this.date3.getTime());
      date33.setMonth(this.date3.getMonth() + 1)
      var month = this.date3.getMonth() + 1;
      var year = this.date3.getFullYear();
      if (month == 0)
        year += 1;
      //--הוסר עפ דרישת הלקוח שלא יעבור ליום האחרון בחודש אם הרצף עובר לשנה הבאה
      // if (date33.getMonth() == this.date3.getMonth() + 1) {
      // if (date33.getMonth() <= new Date().getMonth())
      //   this.lSequenceCredit[i].dTransactDate = new Date(
      //     this.date3.setMonth(this.date3.getMonth())
      //   );
      // else
      this.lSequenceCredit[i].dTransactDate = new Date(
        this.date3.setMonth(this.date3.getMonth() + 1)
      ); */
      // }
      // else {
      //   var lastDayOfMonth: any = new Date(year, month + 1, 0)
      //   lastDayOfMonth = lastDayOfMonth.getUTCDate() + 1;
      //   this.lSequenceCredit[i].dTransactDate = new Date(
      //     year, month, lastDayOfMonth
      //   );
      //   this.date3 = new Date(this.lSequenceCredit[i].dTransactDate.getTime());

      // }
      this.lSequenceCredit[i].nAmount = this.manualCredit.nAmount;
      this.lSequenceCredit[i].nvComment = this.manualCredit.nvComment;

      this.lSequenceCredit[i].iBankType = this.manualCredit.iBankType;
      this.lSequenceCredit[i].iBranchNumber = this.manualCredit.iBranchNumber;
      this.lSequenceCredit[i].iaccountNumber = this.manualCredit.iaccountNumber;
      this.lSequenceCredit[i].iAssociationId = this.manualCredit.iAssociationId;
      this.lSequenceCredit[i].bToReceipte = this.manualCredit.bToReceipte;
      const control = <FormArray>this.myForm.controls["addresses"];

      control.push(this.initAddress());
      this.onChange(i)
      this.isSequenceCredit = true;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  GetMonthesCodeTable() {
    this.serv
      .GetMonthesCodeTable(this.serv.user.iInstitutionId)
      .then(res => {
        if (res) {
          this.monthes = res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  getCredit() {
    this.serv.GetCredit(this.dFromDate, 0, [], false).then(res => {
      if (res) {
        for (let i = 0; i < res.lCredit.length; i++) {
          if (res.lCredit[i].dCreditDate != null) {
            res.lCredit[i].dCreditDate2 = moment(
              res.lCredit[i].dCreditDate
            ).format("DD/MM/YYYY");
          }
          if (res.lCredit[i].dTransactDate != null) {
            res.lCredit[i].dTransactDate2 = moment(
              res.lCredit[i].dTransactDate
            ).format("DD/MM/YYYY");
          }
          if (res.lCredit[i].dCheckDate != null) {
            res.lCredit[i].dCheckDate = moment(
              res.lCredit[i].dCheckDate
            ).format("DD/MM/YYYY");
          }
        }
        this.credit = res;
        this.rowData = res.lCredit;
      }
    });
  }

  deletePayment(params) {
    
    this.isEdit = true;
    this.manualCredit = params;
    this.manualCredit.bDel = true;
    // this.InsUpdCredit();
    this.lManualCredit = new Array<FamilyCredit>();
    this.lManualCredit.push(this.manualCredit);
    if (this.lManualCredit[0].iCreditId) {
      this.serv.InsUpdCredit(this.lManualCredit).then(res => {
        if (res) {
          this.getCredit();
          this.isVisibleManualCreditPopup = false;
          this.initICreditMethodType();
          this.isVisibleCheckPopup = false;
          this.isChecks = false;
        }
      });

    } else if (this.lManualCredit[0].iCheckId) {

      this.serv.InsUpdCheck(this.lManualCredit).then(res => {
        if (res) {
          this.getCredit();
          this.isVisibleManualCreditPopup = false;
          this.initICreditMethodType();
          this.isVisibleCheckPopup = false;
          this.isChecks = false;
        }
      });
    }
  }

  onChangeDate() {
    this.getCredit();
  }

  addManualPayment(bRefund) {
    this.manualCredit = new FamilyCredit();
    this.manualCreditTitle = bRefund ? "זיכוי" : "תשלום ידני";
    this.manualCredit.bImmediateCharge = bRefund;
    this.manualCredit.bRefund = bRefund;
    this.initICreditMethodType();
    this.isVisibleManualCreditPopup = true;
    this.isEdit = false;
    this.isSequence = 0;
  }

  onDateInput(event: any, controlName: string) {
    // Clear the previous timer
    clearTimeout(this.typingTimer);

    // Set a new timer (debounce)
    this.typingTimer = setTimeout(() => {
      const inputDate = event.target.value;
      const parsedDate = this.parseDate(inputDate);

      // If the date is valid, update the form control
      const control = this.form.get(controlName);
      if (control) {
        control.setValue(parsedDate);
      }
    }, 1000); // 1000ms delay
  }

  parseDate(dateString: string): Date | null {
    if (!dateString) return null;

  // Split by various possible delimiters: '/', '-', ' ', '.'
    const parts = dateString.split(/[/\-\s.]+/);
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // months are zero-indexed
      const year = parseInt(parts[2], 10);

      // Check if the parsed date is valid
      if (this.isValidDate(day, month, year)) {
        return new Date(year, month, day);
      }
    }
    return null;
  }

  isValidDate(day: number, month: number, year: number): boolean {
    // Check if the date is valid by creating a Date object
    const date = new Date(year, month, day);
    return (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    );
  }

  addSequenceCredit() {
    this.isEdit = false;
    this.isSequence = 1;
    this.myNgForm.resetForm();
    this.sumCredit = null;
    this.manualCredit = new FamilyCredit();
    const control = <FormArray>this.myForm.controls["addresses"];
    let l = control.length;
    for (let i = 0; i < l; i++) {
      control.removeAt(0);
    }
    control.removeAt(0);
    this.isVisibleCheckPopup = true;
    this.addAddresses = false;
    if (this.isChecks) {
      this.sumCredit = 1;
      this.titleForSequence = "רצף שיקים";
      this.serv.GetAssociationCredit().then(res => { //כדי שיביא את כל העמותות
        if (res) {
          this.serv.AssociationCredit = res.lCodeTable;
         }
      });
      this.form.controls["iCreditMethodType"].clearValidators();
      this.form.controls["iCreditMethodType"].updateValueAndValidity();
      this.form.controls["iPaymentMethodId"].clearValidators();
      this.form.controls["iPaymentMethodId"].updateValueAndValidity();
    } else {
      this.sumCredit = 0;
      this.titleForSequence = "רצף תשלומים"
      this.form.controls["iCreditMethodType"].setValidators(Validators.required);
      this.form.controls["iCreditMethodType"].updateValueAndValidity();
      if (this.form.controls["iPaymentMethodId"] != undefined) {
        // this.form.controls["iPaymentMethodId"].setValidators(Validators.required);
        this.form.controls["iPaymentMethodId"].clearValidators();
        this.form.controls["iPaymentMethodId"].updateValueAndValidity();
      }
    }
  }

  // onClickRadio(event) {
  //   console.log(event.value)
  //   if (event.value == this.cash) {
  //     this.isVisibleCheck = false
  //     this.isVisibleCreditCard = false
  //     this.isVisibleDirectDebit = false
  //   }
  //   else if (event.value == this.check) {
  //     this.isVisibleCheck = true

  //     this.isVisibleCreditCard = false
  //     this.isVisibleDirectDebit = false
  //   }
  //   else if (event.value == this.directDebit) {
  //     this.isVisibleDirectDebit = true

  //     this.isVisibleCheck = false
  //     this.isVisibleCreditCard = false
  //   }
  //   else if (event.value == this.creditCard) {
  //     this.isVisibleCreditCard = true
  //     this.isVisibleDirectDebit = false
  //     this.isVisibleCheck = false
  //   }
  // }

  GetCreditCodeTables() {
    this.lCreditCard = new Array<CodeTable>();
    this.lDirectDebit = new Array<CodeTable>();
    this.serv.GetCreditCodeTables(this.isSequence).then(res => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].iPaymentMethodType == 13) {
          //אשראי
          this.lCreditCard.push({
            length: null,
            id: res[i].iPaymentMethodId,
            text: res[i].nvPaymentMethod
          });
        } else if (res[i].iPaymentMethodType == 14) {
          //הו"ק
          this.lDirectDebit.push({
            length: null,
            id: res[i].iPaymentMethodId,
            text: res[i].nvPaymentMethod
          });
        }
      }
    });
  }

  InsUpdCredit() {
    
    // if ((this.serv.user.iReceiptCompany == 1078 || this.serv.user.iReceiptCompany == 1079) && !this.manualCredit.iAssociationId)
    // return;
    if (this.isVisibleCreditCard == true && (this.manualCredit.iPaymentMethodId == undefined || !this.manualCredit.iAssociationId)) {
      this.dailog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "לא נבחרה רשימת אשראי ועמותה"
      });
      return;
    }
    if (this.isVisibleDirectDebit == true && (this.manualCredit.iPaymentMethodId == undefined)) {
      this.dailog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "לא נבחרה רשימת הוראת קבע"
      });
      return;
    }
    if (!this.manualCredit.bBlankCheck && (this.manualCredit.nAmount == null || this.manualCredit.nAmount == undefined )) {
      this.dailog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "לא הוקלד סכום"
      });
      return;
    }

    if (this.isChecks && !this.isEdit) {
      var i = 0;
      var doubleBlankCheckForSameMonth = false;
      this.lSequenceCredit.forEach(element => {
        if ((element.nAmount as any) == "")
          element.nAmount = null;
        if (element.bBlankCheck) {
          for (let index = i + 1; index < this.lSequenceCredit.length; index++) {
            if (this.lSequenceCredit[index].bBlankCheck && this.lSequenceCredit[index].dTransactDate.getFullYear() == element.dTransactDate.getFullYear() && this.lSequenceCredit[index].dTransactDate.getMonth() == element.dTransactDate.getMonth()) {

              doubleBlankCheckForSameMonth = true;
            }

          }
          if (this.manualCredit.bBlankCheck &&
            this.manualCredit.dTransactDate.getFullYear() == element.dTransactDate.getFullYear() &&
            this.manualCredit.dTransactDate.getMonth() == element.dTransactDate.getMonth()) {
            doubleBlankCheckForSameMonth = true;
          }

        }
        i++;
      });
      if (doubleBlankCheckForSameMonth) {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "הכנסתם יותר מצק ריק אחד לאותו חודש עליכם לשנות את אחד הנתונים"
        });
        return;
      }
    }
    this.lManualCredit = new Array<FamilyCredit>();
    if (!this.isEdit && !this.isSequenceCredit) {
      this.manualCredit.iCreditId = -1;
      // if(this.manualCredit.iCreditMethodType== 13 ||this.manualCredit.iCreditMethodType== 14 ||this.manualCredit.iCreditMethodType== 38  )
      if (
        this.manualCredit.iCreditMethodType == 49 ||
        this.manualCredit.iCreditMethodType == 76 ||
        this.manualCredit.iCreditMethodType == 50
      ) {
        this.manualCredit.iStatusType = 33;
      }
      this.lManualCredit.push(this.manualCredit);
    } else if (this.isEdit && !this.isSequenceCredit ) {
      this.lManualCredit.splice(0, this.lManualCredit.length);
      if (this.manualCredit.iCreditMethodType != 49 && this.manualCredit.iCreditMethodType != 76 && !this.isChecks) 
      this.manualCredit.iStatusType = 32;
      this.lManualCredit.push(this.manualCredit);
    }
    if (this.isSequenceCredit) {
      this.sequenceCredit.iCreditId = -1;
      // this.sequenceCredit.iCreditMethodType = 38;
      // this.sequenceCredit.iStatusType = 32;
      this.lManualCredit.push(this.manualCredit);

      for (let i = 0; i < this.lSequenceCredit.length; i++) {
        this.lSequenceCredit[i].iCreditId = -1;
        // this.lSequenceCredit[i].iCreditMethodType = 38;
        // this.lSequenceCredit[i].iStatusType = 32;
        this.lManualCredit.push(this.lSequenceCredit[i]);
      }
      this.isSequenceCredit = false;
    }
    if (this.isEdit) {
      if (this.lManualCredit[0].iCreditId) {
        this.serv.InsUpdCredit(this.lManualCredit).then(res => {
          if (res) {
            this.getCredit();
            this.isVisibleManualCreditPopup = false;
            this.initICreditMethodType();
            this.isVisibleCheckPopup = false;
            this.isChecks = false;
          }
        });
      } else if (this.lManualCredit[0].iCheckId) {

        this.serv.InsUpdCheck(this.lManualCredit).then(res => {
          if (res) {
            this.getCredit();
            this.isVisibleManualCreditPopup = false;
            this.initICreditMethodType();
            this.isVisibleCheckPopup = false;
            this.isChecks = false;
          }
        });
      }
    } else {
      if (!this.isChecks) {
        this.serv.InsUpdCredit(this.lManualCredit).then(res => {
          if (res) {
            this.getCredit();
            this.isVisibleManualCreditPopup = false;
            this.isVisibleCheckPopup = false;
            this.isChecks = false;
          }
        });
      } else {


        this.serv.InsUpdCheck(this.lManualCredit).then(res => {
          if (res) {
            this.getCredit();
            this.isVisibleManualCreditPopup = false;
            this.isVisibleCheckPopup = false;
            this.isChecks = false;
          }
        });
      }

    }

  }

  onChangeCreditStatus(event) {
    if (
      this.serv.CreditStatusType.filter(c => c.id === event.value)[0].text ==
      "שולם"
    ) {
      this.manualCredit.dTransactDate = new Date();
    }
  }

  onChangeCreditMethod(event) {
    // this.manualCredit = new FamilyCredit();
    this.manualCredit.dCreditDate = new Date();
    this.manualCredit.dTransactDate = new Date();
    this.manualCredit.bDel = false;
    this.manualCredit.iCreditMethodType = event.value;
    console.log(event.value);
    for (let i = 0; i < this.serv.lCreditMethodType.length; i++) {
      if (this.serv.lCreditMethodType[i].id == event.value) {
        if (this.serv.lCreditMethodType[i].id == 13) {//אשראי
/*           this.serv.GetAssociation().then(res => { // כדי שיביא רק את העמותות של אשראי
            if (res) {
              this.serv.AssociationCredit = res.lCodeTable;
             }
          }); */
          // if(this.serv.AssociationCreditTemp)
          //   this.serv.AssociationCredit = this.serv.AssociationCreditTemp;
          this.form.controls["iAssociationId"].setValidators([Validators.required]);
          this.form.controls["iAssociationId"].updateValueAndValidity();
          this.form.controls["iPaymentMethodId"].setValidators([Validators.required]);
          this.form.controls["iPaymentMethodId"].updateValueAndValidity();

          this.isVisibleCreditCard = true;
          this.isVisibleDirectDebit = false;
          this.isVisibleCheck = false;
          this.manualCredit.iStatusType = 32;
          this.form1.controls["iStatusType"].disable();
          this.GetCreditCodeTables();
        } else if (this.serv.lCreditMethodType[i].id == 14) {//הוק
          this.form.controls["iAssociationId"].setValidators(null);
          this.form.controls["iAssociationId"].updateValueAndValidity();
          this.form.controls["iPaymentMethodId"].setValidators([Validators.required]);
          this.form.controls["iPaymentMethodId"].updateValueAndValidity();

          this.isVisibleDirectDebit = true;
          this.isVisibleCheck = false;
          this.isVisibleCreditCard = false;
          this.manualCredit.iStatusType = 32;
          this.form1.controls["iStatusType"].disable();
          this.GetCreditCodeTables();
        } else if (this.serv.lCreditMethodType[i].id == 38) {//תשלום עבור צק
          this.form.controls["iAssociationId"].setValidators(null);
          this.form.controls["iAssociationId"].updateValueAndValidity();
          this.form.controls["iPaymentMethodId"].setValidators(null);
          this.form.controls["iPaymentMethodId"].updateValueAndValidity();
          this.isVisibleCheck = true;
          this.isVisibleCreditCard = false;
          this.isVisibleDirectDebit = false;
          this.manualCredit.iStatusType = 32;
          // this.form1.controls["iStatusType"].disable();
        } else if (this.serv.lCreditMethodType[i].id == 49 || this.serv.lCreditMethodType[i].id == 76) {//"מזומן"
         // this.serv.GetAssociationCredit().then(res => { 
          this.serv.GetAssociationCredit().then(res => { //כדי שיביא את כל העמותות
          if (res) {
            // this.serv.AssociationCreditTemp = this.serv.AssociationCredit;
            this.serv.AssociationCredit = res.lCodeTable;
          this.form.controls["iAssociationId"].setValidators(null);
          this.form.controls["iAssociationId"].updateValueAndValidity();
          this.form.controls["iPaymentMethodId"].setValidators(null);
          this.form.controls["iPaymentMethodId"].updateValueAndValidity();
          this.isVisibleCheck = false;
          this.isVisibleCreditCard = false;
          this.isVisibleDirectDebit = false;
          this.form1.controls["iStatusType"].disable();
          this.manualCredit.iStatusType = 33;
          }
        });
        } else if (this.serv.lCreditMethodType[i].id == 50) {//"העברה בנקאית"
          this.serv.GetAssociationCredit().then(res => { //כדי שיביא את כל העמותות
            if (res) {
              this.serv.AssociationCredit = res.lCodeTable;
             }
          });
              
          this.form.controls["iAssociationId"].setValidators(null);
          this.form.controls["iAssociationId"].updateValueAndValidity();
          this.form.controls["iPaymentMethodId"].setValidators(null);
          this.form.controls["iPaymentMethodId"].updateValueAndValidity();
          this.isVisibleCheck = false;
          this.isVisibleCreditCard = false;
          this.isVisibleDirectDebit = false;
          this.form1.controls["iStatusType"].enable();
          this.manualCredit.iStatusType = 33;
        }
      }
    }
  }

  makeStatisUndefined(i) {
    if (i) {
      if (!(this.isChecks && this.lSequenceCredit[i].bBlankCheck))
        this.lSequenceCredit[i].iStatusType = undefined
    } else {
      if (!(this.isChecks && this.manualCredit.bBlankCheck))
        this.manualCredit.iStatusType = undefined
    }

  }

  onSelectMonthDay(event) {
    this.creditDateIndex = event.source.value;
  }

  onChangeCreditCard(event) {
    if (event.value == 0) {
      //אם בחר בגביה ח"פ
      this.isVisibleFieldsCreditCard = true;
    } else {
      this.isVisibleFieldsCreditCard = false;
    }
  }

  editICreditMethodType(iCreditMethodType) {
    if (iCreditMethodType == 13) {//אשראי
      this.isVisibleCreditCard = true;
      this.isVisibleDirectDebit = false;
      this.isVisibleCheck = false;
      // this.manualCredit.iStatusType = 32;
      this.GetCreditCodeTables();
    } else if (iCreditMethodType == 14) {//הוק
      this.isVisibleDirectDebit = true;
      this.isVisibleCheck = false;
      this.isVisibleCreditCard = false;
      //this.manualCredit.iStatusType = 32;
      this.GetCreditCodeTables();
    } else if (iCreditMethodType == 38) {//תשלום עבור צק
      this.isVisibleCheck = true;
      this.isVisibleCreditCard = false;
      this.isVisibleDirectDebit = false;
      //this.manualCredit.iStatusType = 32;
    } else if (iCreditMethodType == 49 || iCreditMethodType == 76) {//"מזומן"
      this.isVisibleCheck = false;
      this.isVisibleCreditCard = false;
      this.isVisibleDirectDebit = false;
      this.serv.GetAssociationCredit().then(res => { //כדי שיביא את כל העמותות
        if (res) {
          this.serv.AssociationCredit = res.lCodeTable;
         }
      });
      //this.manualCredit.iStatusType = 33;
    } else if (iCreditMethodType == 50) {//"העברה בנקאית"
      this.isVisibleCheck = false;
      this.isVisibleCreditCard = false;
      this.isVisibleDirectDebit = false;
      this.serv.GetAssociationCredit().then(res => { //כדי שיביא את כל העמותות
        if (res) {
          this.serv.AssociationCredit = res.lCodeTable;
         }
      });
      //this.manualCredit.iStatusType = 33;
    }
  }

  initICreditMethodType() {
    this.isVisibleCheck = false;
    this.isVisibleCreditCard = false;
    this.isVisibleDirectDebit = false;
  }

  editPayment(params) {
    if (params.iStatusType !=34 && params.iStatusType != 35 && params.iStatusType != 1091){
      this.editICreditMethodType(params.iCreditMethodType)
      if (params.iCreditId) {
        this.manualCredit.iCreditId = params.iCreditId;
        this.serv.GetUpdateCredit(params.iCreditId).then(res => {
          if (res) {
            this.manualCredit = res;
          }
        });
      }
      else if (params.iCheckId) {
        this.manualCredit.iCheckId = params.iCheckId;

        this.serv.GetUpdateCheck(params.iCheckId).then(res => {
          if (res) {
            this.manualCredit = res;
            console.log(res)
            this.isChecks = true;
          }
        });
      }
      this.isEdit = true;
      this.isVisibleManualCreditPopup = true;
    }else{
      if (params.iCheckId) {
        this.isChecks = true;
      }
      this.IsVisibleEditReturnPopup = true;
    }
  }

  printListPDF() {
    
    //if (!this.serv.user.bRivchit) {
      if ((this.serv.user.iReceiptCompany != 1078 &&  this.serv.user.iReceiptCompany!= 1079) || this.serv.user.bPermissionReceipt != true ) {
      let str = '<h1 style="text-align: center; font-weight: bold; font-size: 35px; padding-top: 30%; ">  אישור תשלום ' + '</h1>';
      str += '<br><h1 style="text-align:right; font-weight: bold; font-size: 25px; ">  לכבוד ' + this.serv.currentFamily.nvFamilyName + ' ' +
        this.serv.currentFamily.nvFatherName + ' ו' + this.serv.currentFamily.nvMotherName;
      str += '</h1><br>';
      str += '<table style="width: 100%; border-spacing: 0 ;cellpadding="5";  direction: rtl" border="1";padding-top: 10%; " id="Table1"> <tr style=" height: 40px; text-align: center; font-weight: bold; font-size: 16px; background-color: #00b4e6; color: white"> <td>עבור חודש</td> <td>תאריך צק</td> <td>תאריך ביצוע</td> <td>אופן תשלום</td> <td>סכום</td> <td>סטטוס</td> <td>מס אישור</td> <td>הערות</td></tr>'
      let count: number = 0;
      this.rowData.forEach(element => {
        if (element.iStatusType == 33 && element.nvToReceipte == "כן") { // רק אם שולם ורק אם נבחר להפקת קבלה
          count += element.nAmount;
          str += '<tr style="height: 35px; text-align: center; font-size: 15px;"><td>';
          if (element.dCreditDate2)
            str += element.dCreditDate2;
          str += '</td><td>'
          if (element.dCheckDate)
            str += element.dCheckDate;
          str += '</td><td>'
          if (element.dTransactDate2)
            str += element.dTransactDate2;
          str += '</td><td>'
          if (element.nvPaymentMethodType)
            str += element.nvPaymentMethodType;
          str += '</td><td>'
          if (element.nAmount)
            str += element.nAmount;
          str += '</td><td>'
          if (element.nvStatusType)
            str += element.nvStatusType;
          str += '</td><td>'
          if (element.nvConfirmationNum)
            str += element.nvConfirmationNum;
          str += '</td><td>'
          if (element.nvComment)
            str += element.nvComment;
          str += '</td> </tr>'
        }
      });
      str += '</table>';
      str += '<br><h1 style="text-align:center; font-weight: bold; font-size: 20px; ">  סה"כ : ' + count + '  ש"ח.</h1>';

      this.serv.PaymentToPDF2(str, null);
    }

    else if ((this.serv.user.iReceiptCompany == 1078 || this.serv.user.iReceiptCompany ==1079) && this.serv.user.bPermissionReceipt == true  ) {
      this.serv.PrintReceiptFamily(this.serv.user.iUserId, this.serv.user.nvGuide,
        this.dFromDate, this.serv.user.iInstitutionId, 0, 0)
    }
  }

  printPDF(params) {
    //if (!this.serv.user.bRivchit) {
    if ((this.serv.user.iReceiptCompany != 1078 &&  this.serv.user.iReceiptCompany!= 1079) || this.serv.user.bPermissionReceipt != true) {
      let str = '<h1 style="text-align: center; font-weight: bold; font-size: 35px; padding-top: 30%; ">  אישור תשלום ' + '</h1>';
      str += '<br><h1 style="text-align:right; font-weight: bold; font-size: 25px; ">  לכבוד ' + params.nvFamilyName;
      str += '</h1><br>';
      str += '<table style="width: 100%; border-spacing: 0 ;cellpadding="5";  direction: rtl" border="1";padding-top: 10%; " id="Table1"> <tr style=" height: 40px; text-align: center; font-weight: bold; font-size: 16px; background-color: #00b4e6; color: white"> <td>עבור חודש</td> <td>תאריך צק</td> <td>תאריך ביצוע</td> <td>אופן תשלום</td> <td>סכום</td> <td>סטטוס</td> <td>מס אישור</td> <td>הערות</td></tr>   <tr style="height: 35px; text-align: center; font-size: 15px;"><td>'
      if (params.dCreditDate2)
        str += params.dCreditDate2;
      str += '</td><td>'
      if (params.dCheckDate)
        str += params.dCheckDate;
      str += '</td><td>'
      if (params.dTransactDate2)
        str += params.dTransactDate2;
      str += '</td><td>'
      if (params.nvPaymentMethodType)
        str += params.nvPaymentMethodType;
      str += '</td><td>'
      if (params.nAmount)
        str += params.nAmount;
      str += '</td><td>'
      if (params.nvStatusType)
        str += params.nvStatusType;
      str += '</td><td>'
      if (params.nvConfirmationNum)
        str += params.nvConfirmationNum;
      str += '</td><td>'
      if (params.nvComment)
        str += params.nvComment;
      str += '</td> </tr></table>'
      this.serv.PaymentToPDF2(str, null);
    }
    else if ((this.serv.user.iReceiptCompany == 1078 || this.serv.user.iReceiptCompany ==1079) && this.serv.user.bPermissionReceipt == true ){
      this.serv.PrintReceiptFamily(this.serv.user.iUserId, this.serv.user.nvGuide,
        null, this.serv.user.iInstitutionId, params.iCreditId, params.iCheckId)
    }
    // params.dTransactDate
  }

  showCreditList() {
    
    // if(this.manualCredit.iCreditMethodType==49){
    //   this.form.controls["iAssociationId"].setValidators(null);
    //   this.form.controls["iPaymentMethodId"].setValidators(null);
    // }
    if (this.form.valid) {
      const control = <FormArray>this.myForm.controls["addresses"];
      let l = control.length;
      for (let i = 0; i < l; i++) {
        control.removeAt(0);
      }
      control.removeAt(0);
      this.lSequenceCredit = new Array<FamilyCredit>();
      this.addAddress();
    }
  }

  ToggleStatus(paymentToToggle,bDone){
    this.serv.ToggleStatus(paymentToToggle.iCreditId ,bDone, this.isChecks).then(res => {
      if (res) {
        this.getCredit();
        this.IsVisibleEditReturnPopup = false;
      }
    });
  }

  /*
 */
}
