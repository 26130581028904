
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseManager } from 'ag-grid-enterprise';
import { environment } from 'src/environments/environment';
LicenseManager.setLicenseKey("CompanyName=T/A Nissim Hazan,LicensedApplication=Smart,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011325,ExpiryDate=21_October_2021_[v2]_MTYzNDc3MDgwMDAwMA==291891260fe8b865c87b66b4ff3d58d9");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit() {
    if (location.hostname == "active-smart.co")
      if (environment.production) {
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
      }

  }

  title = 'app';
  constructor(private router: Router) {
    LicenseManager.setLicenseKey("CompanyName=T/A Nissim Hazan,LicensedApplication=Smart,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011325,ExpiryDate=21_October_2021_[v2]_MTYzNDc3MDgwMDAwMA==291891260fe8b865c87b66b4ff3d58d9");
  }

}


