import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MatCheckboxService {

  public selectedItems:any[]=[];
  
  constructor() {
    // this.selectedItems=[];
   }
}
