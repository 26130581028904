import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Form, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ServService } from '../../app/serv.service';
import { ChildrenDetails } from '../../Models/ChildrenDetails';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { UpdateChildrenComponent } from '../update-children/update-children.component';
import { Observable } from 'rxjs/Observable';



@Component({
  selector: 'app-children-details',
  templateUrl: './children-details.component.html',
  styleUrls: ['./children-details.component.scss']
})
export class ChildrenDetailsComponent implements OnInit {

  whatTime: any = new Date()
  x: number
  flagSumTotal: boolean = true
  sumTotal: number = 0
  numChildren: number = 0
  backgroundColor: string;
  routerSub: any;
  dialogRef: MatDialogRef<UpdateChildrenComponent>;
  dailogOpen: UpdateChildrenComponent

  items = new Array<any>()
  form: FormGroup
  lStatus: Array<number>
  private dailog: MatDialog
  constructor(private route: Router, public service: ServService, private updateChildDailog: MatDialog) {
    this.service.lcDetails = new Array<ChildrenDetails>();
    this.backgroundColor = this.service.backgroundColor[0];
  }
  ngOnInit() {
    this.lStatus = JSON.parse(localStorage.getItem("familyStatuses"));
    this.form = new FormGroup({
      nvFirstName: new FormControl("", Validators.required),
      nTransAmount: new FormControl("", Validators.required),
      nvLevel: new FormControl("", [Validators.required, Validators.minLength(1)]),
      nMealsAmount: new FormControl("", [Validators.required, Validators.minLength(1)]),
      nTuitionAmount: new FormControl("", [Validators.maxLength(9), Validators.minLength(9)]),
      nAmount: new FormControl("", [Validators.required, Validators.maxLength(9), Validators.minLength(9), Validators.pattern('[0-9]*')]),
      nDiscountAmount: new FormControl("", [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('[0]+[5]+[0-9]*')]),
      nTotal: new FormControl("", [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('[0]+[5]+[0-9]*')]),
    })
    this.disable(this.form);
    this.getChildrenDetails()
  }

  getChildrenDetails() {
    this.service.GetChildrenDetails(this.service.user.iUserId, this.service.currentiFamilyId, this.lStatus).then(res => {
      if (res) {
        this.service.lcDetails = res.lChildrenDetails;
        this.numChildren = res.iChildrenCount;
        this.sumTotal = res.nSumTotal;

      }
    }).catch(err => { console.log("err-----" , err); return false; })
  }
  refresh() {
    this.flagSumTotal = true
    this.getChildrenDetails();
 
  }

  updateDetails(iChildId) {
    this.dialogRef = this.updateChildDailog.open(UpdateChildrenComponent, {
      hasBackdrop: true,
      height: '800px',
      width: '1400px',
      data: { iChildId: iChildId }
    });
    this.dialogRef.afterClosed().toPromise().then(res => {
      this.getChildrenDetails();
    });
  }
  disable(frm: FormGroup) {
    Object.keys(frm.controls).forEach(key => {
      frm.get(key).disable();
    });
  }

  onSelectYear() {
    this.getChildrenDetails()
  }

}
