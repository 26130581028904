import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
  selector: 'dailog',
  templateUrl: 'dailog.html',
})
export class dailog {
  globalColor: string;
  array: boolean = false;
  constructor(public dialogRef: MatDialogRef<dailog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.globalColor = "#634ea4"
  }
  ngOnInit() {
    this.array = typeof this.data == 'string' ? false : true;
  }
  onNoClick(res): void {
    this.dialogRef.close(res);
  }
}
